import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import {
  addHours,
  addYears,
  areIntervalsOverlapping,
  format,
  formatISO,
  isBefore,
  startOfHour,
  subHours,
  subYears,
} from 'date-fns';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TaskEvent } from '../../types/util/taskEvent';
import { DateTimePicker } from '../dateTimePicker';
import { SelectItem } from '../../types/util/selectItem';
import { NOT_DELIVERED_COLOR } from '../../constants';
import {
  useDeleteAssignmentMutation,
  useDeleteOrderMutation,
  useGetAllMachineAssignmentsQuery,
  useGetCategoriesQuery,
  usePostMachineOrderMutation,
  usePutApproveAssignmentBodyMutation,
  usePutApproveOrderBodyMutation,
  usePutMoveMachineAssignmentMutation,
  usePutMoveMachineOrderMutation,
} from '../../redux/machine';
import { NewMachineOrder } from '../../types/api/newMachineOrder';
import { Displayable } from '../../types/util/displayable';
import { MoveMachineOrder } from '../../types/api/moveMachineOrder';
import { Assignment } from '../../types/assignment';
import { SearchSelect } from '../searchSelect';
import { Machine } from '../../types/machine';
import { ApproveOrder } from '../../types/api/approveOrder';
import { MoveMachineAssignment } from '../../types/api/moveMachineAssignment';
import { PopConfirm } from '../popConfirm';
import './style.scss';
import { ensureAfter } from '../../logic/dates';
import { OrderCard } from './partials/orderCard';
import { MachineEditRules } from '../../types/machineEditRules';

export const MachineOrderModal: FC<{
      event?: TaskEvent,
      order?: Assignment,
      can: MachineEditRules,
      projects: Displayable[];
      machines?: Machine[];
      isEdit?: boolean,
      refetch: () => void,
      onClose?: () => void,
    }> = ({
      event,
      order,
      can,
      projects,
      machines,
      isEdit = false,
      refetch = () => null,
      onClose = () => null,
    }) => {
      const { data: machineDummies } = useGetCategoriesQuery({ includeSubCategories: true });
      const [updateOrder] = usePutMoveMachineOrderMutation();
      const [updateAssignment] = usePutMoveMachineAssignmentMutation();
      const [assignOrder] = usePutApproveOrderBodyMutation();
      const [approveAssignment, {
        isLoading: isApproved,
      }] = usePutApproveAssignmentBodyMutation();
      const [post] = usePostMachineOrderMutation();
      const [deleteOrder] = useDeleteOrderMutation();
      const [deleteAssignment] = useDeleteAssignmentMutation();
      const [spinner, setSpinner] = useState<boolean>(false);
      const [from, setFrom] = useState<Date>(new Date());
      const [to, setTo] = useState<Date>(new Date());
      const [project, setProject] = useState<SelectItem|undefined>();
      const [machine, setMachine] = useState<SelectItem|undefined>();
      const [subCat, setSubCat] = useState<SelectItem|undefined>();
      const [comment, setComment] = useState<string|undefined>();
      const [internalComment, setInternalComment] = useState<string|undefined>();
      const [assignMachine, setAssignMachine] = useState<boolean>(false);
      const [isChanged, setIsChanged] = useState<boolean>(false);
      const mainCat = isEdit ? order?.category || '' : event?.workerType || '';
      const { data: allAssignments } = useGetAllMachineAssignmentsQuery(
        (machine === undefined) || isApproved
          ? skipToken
          : {
            from: formatISO(subYears(startOfHour(new Date()), 1)),
            to: formatISO(addYears(startOfHour(new Date()), 1)),
            internalNumber: machine.id as string,
          },
      );

      const subCategories = useMemo(() => {
        if (!machineDummies) return [];
        const subcats = machineDummies.find((m) => m.name === mainCat)?.subCategories;
        if (!subcats) return [];
        return subcats.map((s) => ({ id: s, label: s })) as SelectItem[];
      }, [machineDummies, mainCat]);

      const machineList = useMemo(() => {
        if (!machines) return [];
        const items = machines
          .filter((m) => !mainCat || m.mainCategoryName === mainCat)
          .sort((a, b) => {
            const aCat = a.subCategoryName === subCat?.label ? `a${a.subCategoryName}` : `b${a.subCategoryName}`;
            const bCat = b.subCategoryName === subCat?.label ? `a${b.subCategoryName}` : `b${b.subCategoryName}`;
            return aCat.localeCompare(bCat, 'nb');
          })
          .map((m) => {
            const driver = m?.assignedDriver?.fullName
              ? ` - ${m.assignedDriver.fullName}`
              : '';
            return ({ id: m.internalNumber, label: `${m.internalNumber} - ${m.subCategoryName} - ${m.modelName}${driver}` });
          }) as SelectItem[];
        return items;
      }, [machines, mainCat, subCat]);

      useEffect(() => {
        if (isEdit) {
          if (!order) return;
          setFrom(new Date(order.from));
          setTo(new Date(order.to));
          setProject({ id: order.project.id, label: `${order.project.id} - ${order.project.projectName}` });
          const driver = order.machine?.assignedDriver?.fullName
            ? ` - ${order.machine.assignedDriver.fullName}`
            : '';
          setMachine(order.machine
            ? {
              id: order.machine.internalNumber,
              label: `${order.machine.internalNumber} - ${order.machine.subCategoryName} - ${order.machine.modelName}${driver}`,
            }
            : undefined);
          setSubCat({ id: order.subCategory, label: order.subCategory });
          setComment(order.orderComment ?? order.order?.comment);
          setInternalComment(order.type === 'Assignment' ? order.comment : undefined);
        } else {
          if (!event) return;
          if (subCategories.some((s) => s.label === event.title)) {
            setSubCat({ id: event.title || event.id, label: event.title || event.id });
          }
          const defaultProject = { id: event.parent, label: `${event.parent} - ${projects.find((p) => p.id === event.parent)?.label}` };
          setFrom(addHours(event.startTime, 7));
          setTo(subHours(event.endTime, 9));
          setProject(defaultProject);
          setComment(undefined);
          setIsChanged(true);
        }
      }, [order, event, isEdit]);

      const projectList: SelectItem[] = projects.map((p) => ({ id: p.id, label: `${p.id} - ${p.label}` }));

      const buttonConfirmText = useMemo(() => {
        if (assignMachine) return 'Tildel';
        if (isEdit) return 'Lagre';
        return 'Send bestilling';
      }, [assignMachine, can.seeApproveMachineButton]);

      const buttonModalStateText = useMemo(() => {
        if (can.seeApproveMachineButton) return 'Godkjenn bestilling';
        if (assignMachine) return 'Avbryt tildeling';
        return 'Tildel maskin';
      }, [assignMachine, can.seeApproveMachineButton]);

      const periodValid = useMemo(() => {
        if (isBefore(from, to)) return true;
        return false;
      }, [from, to]);

      const formValid = useMemo(() => {
        if (isEdit) {
          if (can.seeAssignMachineButton && assignMachine && !machine) return false;
          if (can.seeApproveMachineButton && !machine) return false;
          if (!order || !periodValid || !project || !mainCat || !subCat) return false;
        } else if (!event || !periodValid || !project || !mainCat || !subCat) return false;
        return true;
      }, [
        event,
        order,
        isEdit,
        periodValid,
        project,
        mainCat,
        subCat,
        can.seeAssignMachineButton,
        can.seeApproveMachineButton,
        assignMachine,
        machine,
      ]);

      const approve = () => {
        setSpinner(true);
        if (!isEdit || !order || !machine || !project) return;
        const body: MoveMachineAssignment = {
          from: formatISO(from),
          to: formatISO(to),
          machineInternalNumber: `${machine.id}`,
          projectId: project.id as number,
          comment: internalComment || '',
        };
        approveAssignment(({ id: order.id, body })).unwrap().then(() => {
          setSpinner(false);
          onClose();
        }).catch(() => {
          toast.error('Kunne ikke godkjenne bestilling');
          setSpinner(false);
        });
      };

      const assign = () => {
        if (!isEdit || !order || !machine || !from || !to) return;
        const body: ApproveOrder = {
          from: formatISO(from),
          to: formatISO(to),
          projectId: order.project.id,
          machineInternalNumber: `${machine?.id}`,
          comment: '',
        };
        assignOrder({ id: order.id, body }).unwrap().then(() => {
          refetch();
          setSpinner(false);
          onClose();
        }).catch(() => {
          toast.error('Kunne ikke tildele maskin');
          setSpinner(false);
        });
      };

      const assignmentUpdate = () => {
        if (!isEdit || !order || !machine || !project) return;
        const body: MoveMachineAssignment = {
          from: formatISO(from),
          to: formatISO(to),
          machineInternalNumber: `${machine.id}`,
          projectId: project.id as number,
          comment: internalComment || '',
        };
        updateAssignment(({ id: order.id, body })).unwrap().then(() => {
          refetch();
          setSpinner(false);
          onClose();
        }).catch(() => {
          toast.error('Kunne ikke oppdatere bestilling');
          setSpinner(false);
        });
      };

      const cancelStrings = useMemo(() => {
        switch (can.cancelButtonText) {
          case 'Kansellert': return { success: 'kansellert', error: 'kansellere' };
          case 'Slett': return { success: 'slettet', error: 'slette' };
          default: return { success: 'avslått', error: 'avså' };
        }
      }, [can]);

      const assignmentDelete = () => {
        if (!order) return;
        setSpinner(true);

        deleteAssignment(order.id).unwrap().then(() => {
          refetch();
          onClose();
        }).catch(() => {
          toast.error(`Kunne ikke ${cancelStrings.error} bestilling`);
        })
          .finally(() => setSpinner(false));
      };

      const orderUpdate = () => {
        if (!isEdit || !order || !project || !mainCat || !subCat || !subCat) return;
        const body: MoveMachineOrder = {
          from: formatISO(from),
          to: formatISO(to),
          category: mainCat,
          subCategory: subCat?.label,
          projectId: project.id as number,
          comment: comment || '',
        };
        updateOrder(({ id: order.id, body })).unwrap().then(() => {
          refetch();
          setSpinner(false);
          onClose();
        }).catch(() => {
          toast.error('Kunne ikke oppdatere bestilling');
          setSpinner(false);
        });
      };

      const orderDelete = () => {
        if (!order) return;
        setSpinner(true);
        deleteOrder(order.id).unwrap().then(() => {
          refetch();
          onClose();
        }).catch(() => {
          toast.error(`Kunne ikke ${cancelStrings.success} bestilling`);
        })
          .finally(() => setSpinner(false));
      };

      const send = () => {
        if (isEdit || !event || !mainCat || !subCat || !subCat) return;
        const body: NewMachineOrder = {
          from: formatISO(from),
          to: formatISO(to),
          category: mainCat,
          subCategory: subCat.label,
          projectId: event.parent as number,
          comment,
        };
        post(body).unwrap().then(() => {
          refetch();
          setSpinner(false);
          onClose();
        }).catch(() => {
          setSpinner(false);
          toast.error('Kunne ikke opprette bestilling');
        });
      };

      const confirmHandler = (v: string) => {
        setSpinner(true);
        if (isEdit) {
          if (v === 'Lagre' && order?.type === 'Assignment') return assignmentUpdate();
          if (v === 'Lagre' && order?.type === 'Order') return orderUpdate();

          if (v === 'Tildel') return assign();
        }
        return send();
      };

      const overlapMessage = useMemo(() => {
        if (!machine || !order || !allAssignments) {
          return null;
        }
        const overlappingEvents = [];
        if (order.type === 'Order') {
          overlappingEvents.push(allAssignments.filter((a) => a.machine?.internalNumber === machine.id
        && areIntervalsOverlapping(
          { start: new Date(a.from), end: new Date(a.to) },
          { start: from, end: to },
        )));
        }
        if (order.type === 'Assignment') {
          overlappingEvents.push(allAssignments.filter((a) => order.id !== a.id && a.machine?.internalNumber === machine.id
        && areIntervalsOverlapping(
          { start: new Date(a.from), end: new Date(a.to) },
          { start: from, end: to },
        )));
        }
        if (overlappingEvents.length !== 0) {
          return (
            overlappingEvents[0].map((e) => (
              <span key={e.id} className="event">{format(new Date(e.from), 'dd.MM.yyyy')} - {format(new Date(e.to), 'dd.MM.yyyy') } (prosjekt: {e.project.id} - {e.project.projectName})</span>
            ))
          );
        }
        return null;
      }, [from, to, allAssignments, order, machine]);

      const isOverlap = useMemo(() => {
        if (!machine || !order || !allAssignments) return false;
        if (order.type === 'Order') {
          return allAssignments.some((a) => a.machine?.internalNumber === machine.id
        && areIntervalsOverlapping(
          { start: new Date(a.from), end: new Date(a.to) },
          { start: from, end: to },
        ));
        }
        if (order.type === 'Assignment') {
          return allAssignments.some((a) => order.id !== a.id && a.machine?.internalNumber === machine.id
        && areIntervalsOverlapping(
          { start: new Date(a.from), end: new Date(a.to) },
          { start: from, end: to },
        ));
        }
        return false;
      }, [from, to, machine, allAssignments, order]);

      return (
        <div className="edit-task-component">
          <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 1,
          }}
          >
            { isOverlap && order?.type !== 'Order' && order?.order && (
              <Box className="overlapMessage">
                <span className="title">Maskin overlapper denne perioden fra: </span>
                {overlapMessage}
              </Box>
            )}
            {can.seeOrderCard && order?.order && (
              <OrderCard assignment={order} />
            )}

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <DateTimePicker
                  format="dd.MM.yyyy"
                  disableTime
                  disabled={!can.editTime}
                  size="medium"
                  fullWidth
                  label="Fra"
                  value={from}
                  onChange={(d) => { setTo(ensureAfter(to, d)); setFrom(d); setIsChanged(true); }}
                  closeOnSelect
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <DateTimePicker
                  format="dd.MM.yyyy"
                  disableTime
                  disabled={!can.editTime}
                  size="medium"
                  fullWidth
                  label="Til"
                  value={to}
                  onChange={(d) => { setTo(d); setIsChanged(true); }}
                  minDate={from}
                  closeOnSelect
                />
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <SearchSelect
                label="Prosjekt"
                errorLabel="Velg et prosjekt"
                disabled={!can.editProject}
                required
                value={project}
                onChange={(p) => { setProject(p); setIsChanged(true); }}
              >
                {projectList}
              </SearchSelect>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {can.seeMainCat && (
                <Box sx={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    label="Hovedkategori"
                    variant="outlined"
                    disabled
                    value={mainCat || ''}
                  />
                </Box>
                )}
                {can.seeSubCat && (
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Underkategori"
                    errorLabel="Velg en kategori"
                    required
                    disabled={!can.editSubCat}
                    value={subCat}
                    onChange={(p) => { setSubCat(p); setMachine(undefined); setIsChanged(true); }}
                  >
                    {subCategories}
                  </SearchSelect>
                </Box>
                )}
              </Box>
              {(order?.type === 'Order' ? assignMachine : can.seeMachine) && (
              <Box sx={{ flex: 1 }}>
                <SearchSelect
                  label="Tildelt maskin"
                  errorLabel="Velg en maskin"
                  disabled={!can.editMachine}
                  required
                  value={machine}
                  onChange={(p) => { setMachine(p); setIsChanged(true); }}
                >
                  {machineList}
                </SearchSelect>
              </Box>
              )}
              { isOverlap && order?.type === 'Order' && (
                <Box className="overlapMessage">
                  <span className="title">Maskin overlapper denne perioden fra: </span>
                  {overlapMessage}
                </Box>
              )}
              {can.seeComment && (
              <Box>
                <TextField
                  label="Kommentar"
                  fullWidth
                  multiline
                  disabled={!can.editComment}
                  value={comment}
                  onChange={(e) => { setComment(e.target.value); setIsChanged(true); }}
                  minRows={4}
                />
              </Box>
              )}
            </Box>

            {can.seeOrderComment && order?.order && (
            <Box>
              <TextField
                variant="standard"
                label="Bestillingskommentar"
                InputProps={{
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                multiline
                disabled
                value={comment}
                minRows={2}
              />
            </Box>

            )}
            {can.seeInternalComment && order?.order && (
            <Box>
              <TextField
                label="Internkommentar"
                fullWidth
                multiline
                disabled={!can.editInternalComment}
                value={internalComment}
                onChange={(e) => { setInternalComment(e.target.value); setIsChanged(true); }}
                minRows={3}
              />
            </Box>
            )}
            {!periodValid && (
              <Typography color={NOT_DELIVERED_COLOR} align="right">Fra tid må være før til tid</Typography>
            )}

            <Box color="#666666" display="flex" flexDirection="column">
              {can.seeLastEditBy && (
              <span>
                Sist endret
                {' '}
                { order?.lastEditTime ? format(new Date(order.lastEditTime), "dd.MM.yyyy 'kl.' HH:mm")
                  : order?.createdTimestamp && format(new Date(order.createdTimestamp), "dd.MM.yyyy 'kl.' HH:mm")}
                { order?.lastEditedByName ? ` av ${order.lastEditedByName}`
                  : order?.createdByName && ` av ${order.createdByName}` }
              </span>
              )}
              {can.seeApprovedBy && (
              <span>
                Godkjent
                {' '}
                { order?.approvedTime ? format(new Date(order.approvedTime), "dd.MM.yyyy 'kl.' HH:mm")
                  : order?.lastEditTime && format(new Date(order.lastEditTime), "dd.MM.yyyy 'kl.' HH:mm")}
                { order?.approvedByName ? ` av ${order.approvedByName}`
                  : order?.lastEditedByName && ` av ${order.lastEditedByName}` }
              </span>
              )}
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: (can.seeAssignMachineButton || can.seeApproveMachineButton) || can.seeCancelButton
                ? 'space-between'
                : 'flex-end',
            }}
            >
              <Box display="flex" gap={2}>
                {(can.seeAssignMachineButton || can.seeApproveMachineButton) && (
                <Button
                  variant="contained"
                  color={can.seeApproveMachineButton ? 'primary' : 'info'}
                  disabled={spinner}
                  onClick={() => (can.seeApproveMachineButton ? approve() : setAssignMachine(!assignMachine))}
                  sx={{ minWidth: spinner && can.seeApproveMachineButton ? '200px' : null }}
                >
                  {spinner && can.seeApproveMachineButton ? <CircularProgress size={24} /> : buttonModalStateText}
                </Button>
                )}
                {can.seeCancelButton && (
                <PopConfirm
                  confirmText={can.cancelButtonText}
                  buttonText={can.cancelButtonText}
                  buttonColor="primary"
                  buttonVariant="outlined"
                  buttonProps={{ disabled: spinner }}
                  content={(
                    <>
                      Vil du {can.cancelButtonText} denne oppgaven?<br />
                      Det vil ikke være mulig å angre
                    </>
                )}
                  onConfirm={order?.type === 'Order' ? orderDelete : assignmentDelete}
                />
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button disabled={spinner} color="primary" variant="outlined" onClick={onClose}>{can.closeButtonText}</Button>
                {can.seeSaveButton && (
                <Button
                  variant="contained"
                  value={buttonConfirmText}
                  disabled={!formValid || spinner || !isChanged}
                  onClick={(e) => confirmHandler(e.currentTarget.value)}
                >
                  {spinner ? <CircularProgress size={24} /> : buttonConfirmText}
                </Button>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      );
    };
