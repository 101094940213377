import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ButtonGroup,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import {
  addDays,
  addMonths,
  addWeeks,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { CalendarView, view } from '../../../../core/types/util/calendarView';
import { format, getWeek } from '../../../../shared/logic/dates';
import { PopMenu } from '../../../../shared/components/popMenu';
import { PopMenuHeader } from '../../../../shared/components/popMenuHeader';
import {
  setOnlyAssignment,
  setShowOrdersWithActiveProjectFilter,
  setWeekend,
} from '../../../../core/redux/viewSetting';
import { useHasRoles } from '../../../../core/helpers/useHasRoles';
import { ProjectFilter } from './projectFilter';
import { Modal } from '../../../../shared/components/modal';
import { NewMachineOrderModal } from '../../../../components/newMachineOrderModal/index';
import { useDispatch, useSelector } from '../../../../core/hooks/redux';

export const TimelineHeader: FC<{
  day?: Date,
  currentView?: CalendarView,
  onDayChange?: (date: Date) => void,
  onCalendarViewChange?: (view: CalendarView) => void,
  filters?: ReactNode,
}> = ({
  day = new Date(),
  currentView = 'month',
  onDayChange = () => null,
  onCalendarViewChange = () => null,
  filters,
}) => {
  const [pickerVal, setPickerVal] = useState<Date>(day);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const showWeekend = useSelector((s) => s.viewSetting.showWeekend);
  const showOrdersWithActiveProjectFilter = useSelector((s) => s.viewSetting.showOrdersWithActiveProjectFilter);
  const showOnlyAssignments = useSelector((s) => s.viewSetting.showOnlyAssignments);
  const sameDay = useMemo(() => (format(day, 'P') === format(new Date(), 'P')), [day]);
  const dispatch = useDispatch();
  const isAdmin = useHasRoles('maskin-koordinator');
  const isProjectOwner = useHasRoles('prosjekteier');

  useEffect(() => {
    if (!isSameDay(pickerVal, day)) {
      setPickerVal(day);
    }
  }, [day]);

  const viewPeriod = useMemo(() => {
    if (currentView === 'maned') return (date: Date, dir: boolean) => startOfDay(addWeeks(date, dir ? 1 : -1));
    if (currentView === 'kvartal') return (date: Date, dir: boolean) => startOfDay(addMonths(date, dir ? 1 : -1));
    if (currentView === 'ar') return (date: Date, dir: boolean) => startOfDay(addMonths(date, dir ? 1 : -1));
    return (date: Date, dir: boolean) => startOfDay(addDays(date, dir ? 1 : -1));
  }, [currentView]);

  const dateFormat = useMemo(() => {
    if (currentView === 'maned') return (date: Date) => `uke ${getWeek(date)} ${format(date, 'yyyy')}`;
    if (currentView === 'kvartal') return (date: Date) => format(date, 'MMMM yyyy');
    if (currentView === 'ar') return (date: Date) => format(date, 'MMMM yyyy');
    return (date: Date) => format(date, 'dd. MMMM yyyy');
  }, [currentView]);

  return (
    <div className="timeline-header-component">
      <span className="align-left">
        <ButtonGroup>
          <Button variant="contained" onClick={() => onDayChange(viewPeriod(day, false))}><ChevronLeft /></Button>
          <Button variant="contained" onClick={() => onDayChange(viewPeriod(day, true))}><ChevronRight /></Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} disabled={sameDay} onClick={() => onDayChange(new Date())}>I dag</Button>
        </ButtonGroup>
        <MobileDatePicker
          value={pickerVal}
          onChange={(v) => setPickerVal(v || day)}
          inputFormat="yyyy-MM-dd'T'HH:mm:ss"
          label="Velg dato"
          onAccept={(newValue) => {
            if (newValue) onDayChange(startOfDay(newValue));
          }}
          renderInput={({ inputRef, inputProps }) => (
            <input
              className="date-text"
              readOnly
              id="datePicker"
              ref={inputRef}
              value={dateFormat(new Date(inputProps?.value))}
              onClick={inputProps?.onClick}
            />
          )}
        />
      </span>
      <ProjectFilter />
      {isAdmin && (
        <FormControlLabel
          label="Kun ikke-godkjente bestillinger"
          control={(
            <Checkbox
              checked={showOnlyAssignments}
              onChange={() => dispatch(setOnlyAssignment(!showOnlyAssignments))}
            />
          )}
        />
      )}
      {(isAdmin || isProjectOwner) && (
        <Button onClick={() => setAddModalOpen(true)}>Ny bestilling</Button>
      )}
      <PopMenu
        button={(
          <IconButton color="primary" aria-label="showWeekend" component="span">
            <FilterListIcon />
          </IconButton>
          )}
        width={filters === undefined ? '200px' : '300px'}
      >
        <span>
          {filters !== undefined && (
            <span>
              <PopMenuHeader>Filter</PopMenuHeader>
              {filters}
            </span>
          ) }
          <span>
            <PopMenuHeader>Visning</PopMenuHeader>
            <Box display="flex" flexDirection="column" gap={1}>
              <FormControlLabel
                label="Vis helg"
                control={(
                  <Checkbox
                    checked={showWeekend}
                    onChange={() => dispatch(setWeekend(!showWeekend))}
                  />
        )}
              />
              <FormControlLabel
                label="Vis alle bestillinger"
                control={(
                  <Checkbox
                    checked={showOrdersWithActiveProjectFilter}
                    onChange={() => dispatch(setShowOrdersWithActiveProjectFilter(!showOrdersWithActiveProjectFilter))}
                  />
        )}
              />
            </Box>
          </span>
        </span>

      </PopMenu>
      <ButtonGroup>
        <Button onClick={() => onCalendarViewChange(view.month)} variant={currentView === 'maned' ? 'contained' : 'outlined'}>Måned</Button>
        <Button onClick={() => onCalendarViewChange(view.quarter)} variant={currentView === 'kvartal' ? 'contained' : 'outlined'}>Kvartal</Button>
        <Button onClick={() => onCalendarViewChange(view.year)} variant={currentView === 'ar' ? 'contained' : 'outlined'}>År</Button>
      </ButtonGroup>
      <Modal
        open={addModalOpen}
        title="Ny maskinbestilling"
        onClose={() => setAddModalOpen(false)}
      >
        <NewMachineOrderModal onClose={() => setAddModalOpen(false)} />
      </Modal>
    </div>
  );
};
