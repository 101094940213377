import React, {
  FC,
  ReactNode,
} from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { Header as SharedHeader } from '../../shared/components/header';
import { msalInstance } from '../../shared/msal';
import { DepartmentSelector } from '../../pages/machinePage/planner/partials/DepartmentSelector';

export const Header: FC<{
  page?: 'project' | 'worker' | 'machine',
  children?: ReactNode
}> = ({
  page,
  children,
}) => {
  const account: AccountInfo|null = msalInstance.getActiveAccount();

  const getInitials = () => {
    let nameArray: string[]|null = null;
    if (account?.name) {
      nameArray = account.name.split(' ');
    }
    const firstName = nameArray ? nameArray[0] : null;
    const lastName = nameArray ? nameArray[nameArray.length - 1] : null;
    if (!firstName || !lastName) { // can not get username
      return 'N/A';
    }
    return (firstName.charAt(0) + lastName.charAt(0));
  };

  return (
    <SharedHeader
      page={page}
      initials={getInitials()}
      middleContent={<DepartmentSelector />}
    >
      {children}
    </SharedHeader>
  );
};
