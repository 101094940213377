import { useCallback } from 'react';
import { useSelector } from './redux';
import { Resource } from '../types/resource';

export const useSearchFilter = () => {
  const searchString = useSelector((s) => s.leftFilterState.searchString);
  const s = searchString.toLocaleLowerCase();
  const check = (str: string | undefined) => (str || '').toLocaleLowerCase().includes(s);
  const filter = useCallback(
    (v: Resource) => searchString === ''
      || v.type === 'Order'
      || check(v.internalNumber)
      || check(v.vehicleRegistrationPlateNumber)
      || check(v.mainCategoryName)
      || check(v.subCategoryName)
      || check(v.modelName)
      || check(v.assignedDriver_fullName)
      || check(v.substituteDriverName)
      || check(v.gpsMake)
      || check(v.sorting)
      || check(v.commentMP)
      || check(v.comment),
    [searchString],
  );
  return filter;
};
