import { FC } from 'react';
import { Tabs } from '../../shared/components/tabs';
import { InfoTab } from './partials/infoTab';
import { SubstituteTab } from './partials/substituteTab';
import { UnavailabilityTab } from './partials/unavailabilityTab';

export const MachineDetails: FC<{id: string}> = ({ id }) => (
  <Tabs tabs={[
    { id: 'info', title: 'Oversikt', content: <InfoTab id={id} /> },
    { id: 'substitute', title: 'Vikar', content: <SubstituteTab id={id} /> },
    { id: 'unavailability', title: 'Utilgjengelighet', content: <UnavailabilityTab id={id} /> },
  ]}
  />
);
