import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useGetMachineQuery } from '../../../shared/redux/machine';
import { UnavailabilityView } from '../../unavailabilityView/index';

export const UnavailabilityTab: FC<{id: string}> = ({ id }) => {
  const { data: machine, isLoading } = useGetMachineQuery(id);
  if (!machine || isLoading) {
    return (
      <div className="project-details">
        <Box display="flex" alignItems="flex-start">
          <Box flex={1} display="flex" flexDirection="column" gap={2}>

            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={300} />

          </Box>
          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>

          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <UnavailabilityView
        id={machine.internalNumber}
        currentDate={new Date()}
      />
    </div>
  );
};
