import {
  FC,
  useEffect,
} from 'react';
import './router.scss';
import {
  BrowserRouter as ReactRouter,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { config as workerConfig } from './shared/config/worker';
import { config as rentalConfig } from './shared/config/rental';
import { urlCombine } from './shared/logic/functions';
import { useSetDefaultFilters } from './pages/machinePage/hooks/useSetDefaultFilters';
import { AccessDeniedPage } from './pages/accessDenied';
import { MachinePage } from './pages/machinePage';
import { ExternalRedirect } from './components/externalRedirect';
import { config } from './config';
import { Unauthenticated } from './shared/components/unauthenticated';
import { useHasAppRole } from './shared/msal';

const Redirect: FC<{ to: string }> = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);
  return null;
};

export const Router = () => {
  const { hasAppRole, isAuthenticated } = useHasAppRole();
  useSetDefaultFilters();
  return (
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: [config.SCOPE] }}
      interactionType={InteractionType.Redirect}
      errorComponent={Unauthenticated}
    >
      <div className="router-wrapper">

        <ReactRouter>
          <Routes>
            {isAuthenticated && hasAppRole && (
            <>
              <Route path="/ingentilgang" element={<AccessDeniedPage />} />
              <Route path="/maskin" element={<MachinePage />} />
              <Route path="/prosjekt" element={<ExternalRedirect to={urlCombine(workerConfig.FRONTEND_URL, 'prosjekt')} />} />
              <Route path="/mannskap" element={<ExternalRedirect to={urlCombine(workerConfig.FRONTEND_URL, 'mannskap')} />} />
              <Route path="/status" element={<ExternalRedirect to={urlCombine(workerConfig.FRONTEND_URL, 'status')} />} />
              <Route path="/internutleie" element={<ExternalRedirect to={urlCombine(rentalConfig.FRONTEND_URL, 'internutleie')} />} />
              <Route path="/" element={<Redirect to="/maskin" />} />
            </>
            )}
            {isAuthenticated && !hasAppRole && (
              <Route path="*" element={<AccessDeniedPage />} />
            )}
          </Routes>
        </ReactRouter>
      </div>
    </MsalAuthenticationTemplate>
  );
};
