import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {
  addHours,
  formatISO,
  isBefore,
  startOfDay,
} from 'date-fns';
import { toast } from 'react-toastify';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { SelectItem } from '../../shared/types/util/selectItem';
import {
  useGetAllMachinesQuery,
  useGetAllProjectsQuery,
  useGetCategoriesQuery,
  usePostCreateAssignmentMutation,
  usePostMachineOrderMutation,
} from '../../shared/redux/machine';
import { NewMachineOrder } from '../../shared/types/api/newMachineOrder';
import { SearchSelect } from '../../shared/components/searchSelect';
import './style.scss';
import { ensureAfter } from '../../shared/logic/dates';
import { labelSort } from '../../core/helpers/functions';
import { Machine } from '../../shared/types/machine';
import { MoveMachineAssignment } from '../../shared/types/api/moveMachineAssignment';
import { selectFromResult } from '../../shared/logic/defaultArray';

export const NewMachineOrderModal: FC<{
      fromDate?: Date,
      toDate?: Date,
      machine?: Machine,
      onClose?: () => void,
    }> = ({
      fromDate = null,
      toDate = null,
      machine = null,
      onClose = () => null,
    }) => {
      const { data: machineDummies } = useGetCategoriesQuery({ includeSubCategories: true }, { selectFromResult });
      const [post] = usePostMachineOrderMutation();
      const [createAssignment] = usePostCreateAssignmentMutation();
      const [spinner, setSpinner] = useState<boolean>(false);
      const [from, setFrom] = useState<Date>(fromDate || addHours(startOfDay(new Date()), 7));
      const [to, setTo] = useState<Date>(toDate || addHours(startOfDay(new Date()), 15));
      const [project, setProject] = useState<SelectItem|undefined>();
      const [mainCat, setMainCat] = useState<SelectItem|undefined>();
      const [subCat, setSubCat] = useState<SelectItem|undefined>();
      const [comment, setComment] = useState<string|undefined>();
      const [selectedMachine, setSelectedMachine] = useState<SelectItem|undefined>();
      const { data: projects } = useGetAllProjectsQuery(undefined, { selectFromResult });
      const { data: machines } = useGetAllMachinesQuery(undefined, { selectFromResult });

      const mainCategories = useMemo(() => (
        machineDummies.map((d): SelectItem => ({ id: d.name, label: d.name }))
      ), [machineDummies]);

      const subCategories = useMemo(() => {
        if (!mainCat) {
          const allSubCats = machineDummies.reduce((a, v) => [...a, ...v.subCategories], [] as string[]);
          return allSubCats.map((s): SelectItem => ({ id: s, label: s })).sort(labelSort);
        }
        const subCats = machineDummies.find((m) => m.name === mainCat?.id)?.subCategories;
        if (subCats) return subCats.map((s): SelectItem => ({ id: s, label: s })).sort(labelSort);
        return [];
      }, [mainCat]);

      const setMainCategory = (cat: SelectItem|undefined) => {
        const newCat = machineDummies.find((d) => d.name === cat?.id);
        if (newCat?.subCategories.length === 1) {
          const newSubCat = newCat.subCategories[0];
          setSubCat({ id: newSubCat, label: newSubCat });
        } else if (subCat && !newCat?.subCategories.some((c) => c === subCat.id)) {
          setSubCat(undefined);
        }
        setMainCat(cat);
      };

      const setSubCategory = (cat: SelectItem|undefined) => {
        if (!mainCat) {
          const newMainCat = machineDummies.find((d) => d.subCategories.some((c) => c === cat?.id));
          if (newMainCat) setMainCat({ id: newMainCat.name, label: newMainCat.name });
        }
        setSubCat(cat);
      };

      const machineList: SelectItem[] = useMemo(() => {
        if (!machines) return [];
        return [...machines]
          .sort((a, b) => {
            const aCat = a.subCategoryName === subCat?.label ? `a${a.subCategoryName}` : `b${a.subCategoryName}`;
            const bCat = b.subCategoryName === subCat?.label ? `a${b.subCategoryName}` : `b${b.subCategoryName}`;
            return aCat.localeCompare(bCat, 'nb');
          })
          .map((m) => {
            const driver = m?.assignedDriver?.fullName
              ? ` - ${m.assignedDriver.fullName}`
              : '';
            return ({ id: m.internalNumber, label: `${m.internalNumber} - ${m.subCategoryName} - ${m.modelName}${driver}` });
          }) as SelectItem[];
      }, [machines]);

      useEffect(() => {
        if (!machineList || !machine) return;

        setSelectedMachine(machineList.find((m) => m.id === machine.internalNumber));
      }, [machineList, machine]);

      const projectList: SelectItem[] = useMemo(() => {
        if (!projects) return [];
        return [...projects]
          .sort((a, b) => (a.id || Infinity) - (b.id || Infinity))
          .map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` }));
      }, [projects]);

      const periodValid = useMemo(() => {
        if (isBefore(from, to)) return true;
        return false;
      }, [from, to]);

      const formValid = useMemo(() => {
        if (!periodValid) return false;
        if (!project) return false;
        if (!selectedMachine && !mainCat) return false;
        if (!selectedMachine && !subCat) return false;
        return true;
      }, [
        periodValid,
        project,
        mainCat,
        subCat,
        selectedMachine,
      ]);

      const send = () => {
        if (!project) return;
        setSpinner(true);
        if (mainCat && subCat) {
          const body: NewMachineOrder = {
            from: formatISO(from),
            to: formatISO(to),
            category: mainCat.label,
            subCategory: subCat.label,
            projectId: project.id as number,
            comment,
          };
          post(body).unwrap().then(() => {
            onClose();
          }).catch(() => toast.error('Kunne ikke opprette bestilling'))
            .finally(() => setSpinner(false));
        } else if (selectedMachine) {
          const body: MoveMachineAssignment = {
            from: formatISO(from),
            to: formatISO(to),
            projectId: project.id as number,
            machineInternalNumber: selectedMachine.id.toString(),
            comment,
          };

          createAssignment(body).unwrap().then(() => {
            onClose();
          }).catch(() => toast.error('Kunne ikke opprettte tildeling'))
            .finally(() => setSpinner(false));
        }
      };

      return (
        <div className="edit-task-component">
          <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 1,
          }}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <DateTimePicker
                  closeOnSelect
                  format="dd.MM.yyyy"
                  disableTime
                  size="medium"
                  fullWidth
                  label="Fra"
                  value={from}
                  defaultTime={7}
                  onChange={(d) => { setTo(ensureAfter(to, d)); setFrom(d); }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <DateTimePicker
                  closeOnSelect
                  format="dd.MM.yyyy"
                  disableTime
                  size="medium"
                  fullWidth
                  label="Til"
                  value={to}
                  defaultTime={15}
                  onChange={(d) => { setTo(d); }}
                  minDate={from}
                />
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <SearchSelect
                label="Prosjekt"
                errorLabel="Velg et prosjekt"
                required
                value={project}
                onChange={(p) => { setProject(p); }}
              >
                {projectList}
              </SearchSelect>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              { machine === null ? (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <SearchSelect
                      label="Hovedkategori"
                      errorLabel="Velg en kategori"
                      required
                      value={mainCat}
                      onChange={setMainCategory}
                    >
                      {mainCategories}
                    </SearchSelect>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <SearchSelect
                      label="Underkategori"
                      errorLabel="Velg en kategori"
                      required
                      value={subCat}
                      onChange={setSubCategory}
                    >
                      {subCategories}
                    </SearchSelect>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Maskin"
                    errorLabel="Velg maskin"
                    required
                    value={selectedMachine}
                    onChange={(p) => { setSelectedMachine(p); }}
                  >
                    {machineList}
                  </SearchSelect>
                </Box>
              )}
              <Box>
                <TextField
                  label="Kommentar"
                  fullWidth
                  multiline
                  value={comment}
                  onChange={(e) => { setComment(e.target.value); }}
                  minRows={4}
                />
              </Box>
            </Box>

            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button disabled={spinner} color="primary" variant="outlined" onClick={onClose}>Avbryt</Button>
                <Button
                  variant="contained"
                  disabled={!formValid || spinner}
                  onClick={send}
                >
                  {spinner ? <CircularProgress size={24} /> : 'Send bestilling'}
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      );
    };
