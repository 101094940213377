import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  Popover,
  TextField,
} from '@mui/material';
import {
  compareAsc,
  formatISO,
  isSameMinute,
  setHours,
  startOfDay,
} from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useDeleteUnavailabilityMutation,
  useGetAllMachinesQuery,
  useGetUnavailabilityQuery,
  usePutUnavailabilityMutation,
  usePostUnavailabilityMutation,
} from '../../shared/redux/machine';
import { LoadingFields } from './partials/LoadingFields';
import './style.scss';
import { NewUnavailability } from '../../shared/types/api/newUnavailability';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { ensureAfter } from '../../shared/logic/dates';
import { Button } from '../../shared/components/button';
import { LastEditInfo } from '../../shared/components/lastEditInfo';
import { useHasRoles } from '../../core/helpers/useHasRoles';

export const EditUnavailability: FC<{
  id: string,
  machineId: string,
  onAction?: () => void
}> = ({
  id,
  machineId,
  onAction = () => null,
}) => {
  const [from, setFrom] = useState<Date>(setHours(startOfDay(new Date()), 7));
  const [to, setTo] = useState<Date>(setHours(startOfDay(new Date()), 15));
  const [comment, setComment] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const {
    data: unavailability,
    isLoading: unavailabilityIsLoading,
  } = useGetUnavailabilityQuery(
    id !== '' && !isDeleting ? { machineId, id } : skipToken,
  );
  const { data: machines } = useGetAllMachinesQuery();

  const isLoading = unavailabilityIsLoading && id !== '';

  const isEdit = unavailability !== undefined;
  const canEdit = useHasRoles('maskin-koordinator');

  const [post, { isLoading: isPosting }] = usePostUnavailabilityMutation();
  const [put, { isLoading: isPutting }] = usePutUnavailabilityMutation();
  const [remove] = useDeleteUnavailabilityMutation();

  const isSaving = isPosting || isPutting;

  const machine = useMemo(() => machines?.find(
    (v) => v.internalNumber === machineId,
  ), [machines, unavailability]);

  useEffect(() => {
    if (unavailability) {
      setFrom(new Date(unavailability.from));
      setTo(new Date(unavailability.to));
      setComment(unavailability.comment);
    }
  }, [unavailability]);

  const hasChanged = useMemo(() => {
    if (!unavailability) return false;
    if (comment !== unavailability.comment) return true;
    if (from && !isSameMinute(from, new Date(unavailability.from))) return true;
    if (to && !isSameMinute(to, new Date(unavailability.to))) return true;
    return false;
  }, [from, to, comment, unavailability]);

  const valid = useMemo(() => {
    if (isEdit && !hasChanged) return false;
    if (compareAsc(from, to) >= 0) return false;
    return true;
  }, [from, to, comment]);

  const submit = () => {
    if (!from) return;
    if (!to) return;
    const body: NewUnavailability = {
      from: formatISO(from),
      to: formatISO(to),
      comment,
    };
    if (isEdit) {
      put({ machineId, id, body }).unwrap().then(() => {
        onAction();
      });
    } else {
      post({ id: machineId, body }).unwrap().then(() => {
        onAction();
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setIsDeleting(true);
    remove({ machineId, id }).unwrap()
      .then(onAction)
      .catch(() => setIsDeleting(false));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popid = open ? 'popover' : undefined;

  return (
    <div className="unavailability-wrapper">
      <div className="unavailability-area">
        {!isLoading ? (
          <div>
            <FormControl fullWidth sx={{ gap: 3 }}>
              <TextField
                label="Maskin"
                value={
                  machine && `${
                    machine.internalNumber
                  } - ${
                    machine.subCategoryName
                  } - ${
                    machine.assignedDriver?.fullName || 'Ingen sjåfør'
                  }`
                }
                disabled
              />
              <Box sx={{ display: 'flex', gap: 3 }}>
                <DateTimePicker
                  label="Fra"
                  value={from}
                  disableTime
                  format="dd.MM.yyyy"
                  closeOnSelect
                  onChange={(date) => {
                    if (!date) return;
                    setFrom(date);
                    setTo(ensureAfter(to, date));
                  }}
                  fullWidth
                  size="medium"
                  disabled={!canEdit}
                />
                <DateTimePicker
                  label="Til"
                  value={to}
                  disableTime
                  format="dd.MM.yyyy"
                  closeOnSelect
                  onChange={(date) => date && setTo(date)}
                  minDate={from}
                  fullWidth
                  size="medium"
                  disabled={!canEdit}
                />
              </Box>
              <TextField
                multiline
                rows={4}
                label="Kommentar"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                disabled={!canEdit}
              />
            </FormControl>
            {isEdit && unavailability && (
              <Box pt={2}>
                <LastEditInfo data={unavailability} />
              </Box>
            )}
            <div className="edit-buttons">
              {isEdit && canEdit ? (
                <Button
                  disabled={isSaving}
                  loading={isDeleting}
                  onClick={handleClick}
                  variant="contained"
                  color="error"
                >
                  Slett
                </Button>
              ) : <div />}
              <span className="flex-gap-10">
                <Button
                  disabled={isSaving || isDeleting}
                  onClick={onAction}
                  variant="outlined"
                >
                  { canEdit ? 'Avbryt' : 'Lukk' }
                </Button>
                { canEdit && (
                  <Button
                    disabled={isDeleting || !valid}
                    loading={isSaving}
                    variant="contained"
                    onClick={submit}
                  >
                    {isEdit ? 'Lagre' : 'Opprett'}
                  </Button>
                )}
              </span>
            </div>
            <Popover
              id={popid}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={8}
              transitionDuration="auto"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <div className="m10">
                <div className="ml5 mb10">
                  <span className="bold-text font-size-17">Er du sikker?</span>
                  <div className="mt5">
                    <span className="font-size-15 italic">
                      Vil du slette denne utilgjengeligheten?<br />
                      Det vil ikke være mulig å angre sletting.
                    </span>
                  </div>
                </div>
                <div className="text-align-end">
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{ m: 0.75 }}
                  >
                    Avbryt
                  </Button>
                  <Button
                    sx={{ m: 0.75 }}
                    variant="contained"
                    color="error"
                    onClick={handleDelete}
                  >
                    Slett
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
        ) : (
          <LoadingFields />
        )}
      </div>
    </div>
  );
};
