import {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { isAfter } from 'date-fns';
import { format } from '../../../shared/logic/dates';
import { useGetMachineQuery, usePutMachineCommentMutation } from '../../../shared/redux/machine';
import { DataField } from '../../../shared/components/dataField';
import { GpsChip } from '../../../shared/components/gpsChip';
import { useHasRoles } from '../../../core/helpers/useHasRoles';

export const InfoTab: FC<{id: string}> = ({ id }) => {
  const { data: machine, isLoading } = useGetMachineQuery(id);
  const [putComment, { isLoading: isUpdating }] = usePutMachineCommentMutation();
  const [comment, setComment] = useState<string>('');
  const isAdmin = useHasRoles('maskin-koordinator');

  useEffect(() => {
    setComment(machine?.comment || '');
  }, [machine]);

  const updateComment = () => {
    if (!isAdmin) return;
    if (machine?.comment === comment || (!machine?.comment && comment === '')) return;
    if (comment.trim().length === 0) setComment('');
    putComment({ id, comment });
  };

  if (!machine || isLoading) {
    return (
      <div className="project-details">
        <Box display="flex" alignItems="flex-start">
          <Box flex={1} display="flex" flexDirection="column" gap={2}>

            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={300} />

          </Box>
          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>

          <Box flex={1} display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
            <Skeleton variant="rectangular" height={35} width={200} />
          </Box>
        </Box>
      </div>
    );
  }
  return (
    <div>
      <Box display="flex" alignItems="stretch" flexDirection="column" gap={2}>
        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <TextField
            fullWidth
            value={!machine.vehicleRegistrationPlateNumber
              ? ' '
              : machine.vehicleRegistrationPlateNumber}
            label="Registreringsnummer"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!machine.mainCategoryName
              ? ' '
              : machine.mainCategoryName}
            label="Kategori"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!machine.subCategoryName
              ? ' '
              : machine.subCategoryName}
            label="Underkategori"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

        </Box>
        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <TextField
            fullWidth
            value={!machine.modelName
              ? ' '
              : machine.modelName}
            label="Modelnavn"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!machine.manufacturerName
              ? ' '
              : machine.manufacturerName}
            label="Produsent"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!machine.modelYear
              ? ' '
              : machine.modelYear}
            label="Modelår"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <TextField
            fullWidth
            value={!machine.tiltRotatorType
              ? ' '
              : machine.tiltRotatorType}
            label="Tiltrotatortype"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!machine.bucketBladeType
              ? ' '
              : machine.bucketBladeType}
            label="Bøttebladtype"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

          <TextField
            fullWidth
            value={!machine.quickCouplingAttachmentSize || machine.quickCouplingAttachmentSize === '0'
              ? ' '
              : machine.quickCouplingAttachmentSize}
            label="Hurtigkoblingsstørrelse"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />

        </Box>
        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <DataField
            label="Tiltrotatorserienummer"
            sx={{ flex: 1 }}
          >
            {!machine.tilRotatorSerialNumber || machine.tilRotatorSerialNumber === '0'
              ? ' '
              : machine.tilRotatorSerialNumber}
          </DataField>

          <DataField
            label="GPS fabrikat"
            sx={{ flex: 1 }}
          >
            {machine.gpsMake ? <GpsChip gps={machine.gpsMake} /> : 'Har ikke GPS'}
          </DataField>

          <DataField
            label="Hurtigkoblingserienummer"
            sx={{ flex: 1 }}
          >
            {!machine.quickCouplingSerialNumber
              ? ' '
              : machine.quickCouplingSerialNumber}
          </DataField>

        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <DataField
            label="Dagsleiepris"
            sx={{ flex: 1 }}
          >
            {
            !machine.dailyRentalPrice
              ? ' '
              : `${machine.dailyRentalPrice} Kr`
    }
          </DataField>

          <DataField
            label="Salgspris"
            sx={{ flex: 1 }}
          >
            {!machine.sellingPrice
              ? ' '
              : `${machine.sellingPrice} Kr`}
          </DataField>

          <DataField
            label="Neste CE godkjennelse"
            sx={{ flex: 1 }}
          >
            <Box display="flex" gap={1} alignItems="center" color="black">

              {!machine.nextCertificationDate
                ? ' '
                : format(new Date(machine.nextCertificationDate), 'dd.MM.yyyy')}
              {machine.nextCertificationDate && isAfter(new Date(), new Date(machine.nextCertificationDate))
                ? (
                  <Tooltip
                    title="CE godkjennelse utgått"
                    followCursor
                    placement="right-start"
                    enterDelay={300}
                    enterNextDelay={300}
                    arrow
                  >
                    <EventBusyIcon color="error" sx={{ alignSelf: 'center', fontSize: '25px' }} />
                  </Tooltip>
                )
                : null}
            </Box>
          </DataField>

        </Box>

        <Box flex={1} display="flex" flexDirection="row" gap={1}>

          <DataField
            label="Fast fører"
            sx={{ flex: 1 }}
          >
            {!machine.assignedDriver?.fullName
              ? ' '
              : machine.assignedDriver.fullName}
          </DataField>

          <DataField
            label="Tlf"
            sx={{ flex: 1 }}
          >
            <span>
              <a href={`tel:${machine.assignedDriver?.mobilePhoneWork}`}>
                {machine.assignedDriver?.mobilePhoneWork}
              </a>
            </span>
          </DataField>

          <DataField
            label="Gangtid"
            sx={{ flex: 1 }}
          >
            {
              `${machine.operatingTime ?? ''}`.trim()
                ? `${machine.operatingTime} KM/TIMER`
                : ''
            }
          </DataField>
        </Box>

        {machine.commentTek && machine.commentTek !== '0' && (
          <TextField
            fullWidth
            multiline
            value={machine.commentTek || ' '}
            label="Kommentar (teknisk)"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        )}
        {machine.commentMP && machine.commentMP !== '0' && (
          <TextField
            fullWidth
            multiline
            value={machine.commentMP || ' '}
            label="Kommentar (MP)"
            variant="standard"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        )}
        <TextField
          fullWidth
          multiline
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={!isAdmin}
          label="Kommentar"
          variant="outlined"
          InputLabelProps={{ sx: { fontSize: 17 }, shrink: true }}
        />
      </Box>
      <Box display={isAdmin ? 'flex' : 'none'} paddingTop={2.5} justifyContent="flex-end">
        <Button
          disabled={isUpdating
                || machine.comment === comment
                || (!machine.comment && (comment === '' || comment.trim().length === 0))}
          variant="contained"
          onClick={updateComment}
        >
          {isUpdating ? <CircularProgress size={24} /> : 'Lagre'}
        </Button>
      </Box>
    </div>
  );
};
