import { ReactNode, useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { formatISO, subDays } from 'date-fns';
import { DateTime } from '../../shared/types/util/dateTime';
import './style.scss';

export type Timeframe = {
  id: string,
  title: string,
  start?: DateTime,
  end?: DateTime,
}

const datestr = (date: Date) => formatISO(date, { representation: 'date' });
const stableArray: any[] = [];

export { TimeframeViewListItem } from './partials/timeframeViewListItem';

export const TimeframeView = <T extends {start: DateTime}|{from: DateTime}>({
  currentDate,
  dataHook,
  filterFunction,
  renderItem,
  onAdd,
  onClose,
  searchLabel = 'Søk',
  noItemsLabel = 'Ingen funnet',
}: {
  currentDate: Date,
  dataHook: (args: {start?: DateTime, end?: DateTime}) => {data?: T[], isLoading: boolean },
  filterFunction: (item: T, search: string) => boolean,
  renderItem: (item: T) => ReactNode,
  onAdd?: () => void,
  onClose?: () => void,
  searchLabel?: string,
  noItemsLabel?: string,
}) => {
  const [search, setSearch] = useState<string>('');

  const timeframes: Timeframe[] = [
    {
      id: 'future',
      title: 'Fremtidige',
      start: datestr(currentDate),
    },
    {
      id: 'last7days',
      title: 'Siste 7 dager',
      start: datestr(subDays(currentDate, 7)),
      end: datestr(currentDate),
    },
    {
      id: 'last14days',
      title: 'Siste 14 dager',
      start: datestr(subDays(currentDate, 14)),
      end: datestr(currentDate),
    },
    {
      id: 'all',
      title: 'Alle',
    },
  ];

  const [selection, setSelection] = useState<Timeframe>(timeframes[3]);

  const range = useMemo(() => ({
    start: selection.start,
    end: selection.end,
  }), [selection]);

  const { data: rawData, isLoading } = dataHook(range);
  const data = useMemo((): T[] => rawData ?? stableArray as T[], [rawData]);

  const getStart = (obj: T): DateTime => {
    if ('start' in obj) {
      return obj.start;
    }
    return obj.from;
  };

  const filteredData = useMemo(() => (
    data.filter((s) => filterFunction(s, search.toLowerCase()) === true)
      .sort((a, b) => (selection.id === 'all'
        ? getStart(b).localeCompare(getStart(a), 'nb')
        : getStart(a).localeCompare(getStart(b), 'nb')))
  ), [search, data, filterFunction]);

  return (
    <div className="substitute-wrapper">
      <div className="substitute-area">
        <div className="header">
          <span className="header-group">
            <ButtonGroup
              fullWidth
              variant="contained"
              aria-label="outlined primary button group"
            >
              {timeframes.map((t) => (
                <Button
                  key={t.id}
                  variant={t.id === selection.id ? 'contained' : 'outlined'}
                  onClick={() => setSelection(t)}
                >
                  {t.title}
                </Button>
              ))}
            </ButtonGroup>
          </span>
          {onAdd && (
          <span className="header-button">
            <ButtonGroup fullWidth aria-label="outlined create button">
              <Button onClick={onAdd} variant="contained"><AddIcon /></Button>
            </ButtonGroup>
          </span>
          )}
        </div>
        <TextField
          sx={{ mt: 1, mb: 1 }}
          label={searchLabel}
          type="search"
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div>
          {!filteredData.length
            ? (
              <div className="subs-empty">{isLoading ? (
                <CircularProgress
                  size={50}
                  sx={{
                    position: 'absolute',
                    top: '65%',
                    left: '50%',
                    marginTop: '-25px',
                    marginLeft: '-25px',
                    zIndex: 1000,
                  }}
                />
              ) : noItemsLabel}
              </div>
            ) : (
              filteredData.map(renderItem)
            )}
        </div>
      </div>

      {onClose && (
        <div className="close-button">
          <Button variant="outlined" onClick={onClose}>Lukk</Button>
        </div>
      )}
    </div>
  );
};
