import React, {
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { ColCellContentArg } from '@fullcalendar/resource';
import { Box, Tooltip } from '@mui/material';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { isAfter } from 'date-fns';
import { GpsChip } from '../../../../shared/components/gpsChip';
import {
  useGetAllMachinesQuery,
  useGetAllWorkersQuery,
} from '../../../../shared/redux/machine';
import { DriverCellContent } from '../partials/driverCellContent';
import { MachineColumn } from '../../../../core/redux/leftFilterState';
import { selectFromResult } from '../../../../shared/logic/defaultArray';

type driverFinder = (
  e: React.MouseEvent,
  id: number|undefined,
  name: string|undefined
) => void;

export const useRenderCellContent = (findDriver: driverFinder) => {
  const { data: rawWorkers } = useGetAllWorkersQuery(undefined, { selectFromResult });
  const workers = useMemo(() => (new Map(
    rawWorkers.map((w) => [w.employeeNumber, w]),
  )), [rawWorkers]);

  const { data: machines } = useGetAllMachinesQuery(undefined, { selectFromResult });
  const machinesExpCert = useMemo(() => (new Map(
    machines
      .filter((m) => m.nextCertificationDate)
      .map((m) => ([m.internalNumber, m.nextCertificationDate])),
  )), [machines]);

  const cellContent = useCallback((c: MachineColumn, e: ColCellContentArg) => {
    // Everything assumes resource exists
    if (!e.resource) return '';
    const colContent = `${e.resource.extendedProps[c] ?? ''}`;
    // If ID is not set, just return column as text
    if (e.resource.id && Number.isNaN(parseInt(e.resource.id, 10))) {
      return colContent;
    }

    // If value is empty-ish (empty or whitespace), set to empty string
    const value: string = (colContent?.trim() && colContent) || '';

    // Get machine certificate expiry date
    const expCert = c === 'internalNumber' && machinesExpCert.get(e.resource.id);

    /** Render regular machine column - handles expired certs */
    const MachineContent = ({ children }: {children: ReactNode}) => (
      <Box
        display="flex"
        width="100%"
        minWidth="5px"
        height="24px"
        alignItems="center"
        className={expCert ? 'expired-column' : ''}
      >
        <span className={expCert ? 'one-line-ellipse' : ''}>
          {children}
        </span>
        {expCert && isAfter(new Date(), new Date(expCert)) && (
          <Tooltip
            title="CE godkjennelse utgått"
            followCursor
            placement="right-start"
            enterDelay={300}
            enterNextDelay={300}
            arrow
          ><EventBusyIcon
            color="error"
            sx={{ alignSelf: 'center', fontSize: '18px' }}
          />
          </Tooltip>
        )}
      </Box>
    );

    /** Render driver column */
    const getDriver = (
      workerId: number|undefined,
      name: string|undefined,
      isSub: boolean = false,
    ) => {
      if (!name) return null;
      const worker = workerId ? workers.get(workerId) : undefined;
      const workerExpCount = worker?.expiredCertifications;
      const workerMissingSecTrainingCount = worker?.missingSecurityTraining;
      return (
        <DriverCellContent
          name={isSub ? `(${name})` : name}
          expiredCerts={workerExpCount}
          missingSecTraining={workerMissingSecTrainingCount}
          onClick={(ev) => findDriver(ev, workerId, name)}
        />
      );
    };

    // Handle specific columns

    if (c === 'gpsMake') {
      return value && (
        <MachineContent>
          <GpsChip gps={value} sx={{ cursor: 'pointer', fontSize: '12px' }} />
        </MachineContent>
      );
    }
    if (c === 'assignedDriver_fullName') {
      const machine = machines.find((m) => m.internalNumber === e.resource?.id);
      const assignedDriver = machine?.assignedDriver
        ? getDriver(
          machine.assignedDriver.employeeNumber,
          machine.assignedDriver.fullName,
        )
        : null;
      const subDriver = getDriver(
        machine?.substituteDriverId,
        machine?.substituteDriverName,
        true,
      );
      if (assignedDriver && subDriver) {
        return (
          <div>
            {assignedDriver}
            {subDriver}
          </div>
        );
      }
      return assignedDriver || subDriver || null;
    }

    // Return regular machine content
    return <MachineContent>{value}</MachineContent>;
  }, [machines, workers, machinesExpCert]);

  return cellContent;
};
