import { useMemo } from 'react';
import {
  addMonths,
  addWeeks,
  endOfMonth,
  formatISO,
  startOfMonth,
} from 'date-fns';
import { startOfWeek, endOfWeek } from '../../shared/logic/dates';
import { useDate } from './useDate';
import { useView } from './useView';
import { view as viewType } from '../types/util/calendarView';
import { DateTime } from '../../shared/types/util/dateTime';

/**
 * Get the current timeframe visible in the timeline
 * */
export const useTimeframe = () => {
  const [date] = useDate();
  const [view] = useView();

  const timeframe = useMemo((): {from: Date, to: Date} => {
    if (view === viewType.year) {
      return {
        from: startOfWeek(startOfMonth(date)),
        to: endOfWeek(endOfMonth(addMonths(date, 11))),
      };
    }
    if (view === viewType.quarter) {
      return {
        from: startOfWeek(startOfMonth(date)),
        to: endOfWeek(endOfMonth(addMonths(date, 2))),
      };
    }
    if (view === viewType.month) {
      return {
        from: startOfWeek(date),
        to: endOfWeek(addWeeks(date, 3)),
      };
    }
    // Default is month
    return {
      from: startOfWeek(date),
      to: endOfWeek(date),
    };
  }, [date, view]);

  return timeframe;
};

/**
 * Get the current timeframe visible in the timeline as DateTime strings
 * */
export const useTimeFrameStrings = (): {from: DateTime, to: DateTime} => {
  const { from: start, to: end } = useTimeframe();
  return {
    from: formatISO(start),
    to: formatISO(end),
  };
};
