import { EventInput } from '@fullcalendar/core';
import { COMPLETED_COLOR, UNAVAILABILITY_COLOR } from '../../shared/constants';
import { Absence } from '../../shared/types/absence';
import { Unavailability } from '../../shared/types/unavailability';

const extUnavailabilityBackgroundOpacity = (p: number) => {
  if (p <= 20) return '33';
  if (p <= 40) return '66';
  if (p <= 60) return '99';
  if (p <= 80) return 'CC';
  return 'FF';
};

export const eventifyAbsence = (absences: Absence[], isAdmin: boolean): EventInput[] => absences.map((a) => ({
  id: `${a.id}-${a.machine?.internalNumber || a.machineId}`,
  groupId: `${a.id}`,
  title: a.title,
  start: new Date(a.startTime),
  end: new Date(a.endTime),
  resourceId: a.machine ? a.machine.internalNumber : a.machineId,
  parent: a.machine ? a.machine.internalNumber : a.machineId,
  borderColor: a.type === 'Absence' ? UNAVAILABILITY_COLOR : `#ffde00${extUnavailabilityBackgroundOpacity(a?.sickPercentage || 100)}`,
  backgroundColor: a.type === 'Absence' ? UNAVAILABILITY_COLOR : `#ffde00${extUnavailabilityBackgroundOpacity(a?.sickPercentage || 100)}`,
  textColor: '#333',
  isAbsence: true,
  editable: isAdmin && a.type === 'Absence',
  resourceEditable: false,
  durationEditable: isAdmin && a.type === 'Absence',
}));

export const eventifyUnavailability = (unavailabilities: Unavailability[], isAdmin: boolean): EventInput[] => unavailabilities.map((u) => ({
  id: `${u.id}`,
  title: 'Utilgjengelighet',
  start: new Date(u.from),
  end: new Date(u.to),
  resourceId: u.machine ? u.machine.internalNumber : u.machineInternalNumber,
  parent: u.machine ? u.machine.internalNumber : u.machineInternalNumber,
  borderColor: COMPLETED_COLOR,
  backgroundColor: COMPLETED_COLOR,
  resourceEditable: false,
  durationEditable: isAdmin,
  startEditable: isAdmin,
}));
