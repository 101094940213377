import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Header } from '../../components/header';
import { useDispatch, useSelector } from '../../core/hooks/redux';
import {
  setMainCategories,
  setSubCategories,
  setSortingFilter,
  useOrganizations,
  setManagerFilter,
} from '../../core/redux/leftFilterState';
import { MultiSearchSelect } from '../../shared/components/multiSearchSelect';
import { Page } from '../../shared/components/page';
import { Planner } from './planner';
import { labelSort } from '../../core/helpers/functions';
import { Modal } from '../../shared/components/modal';
import { MachineDetails } from '../../components/machineDetails';
import { useTimeFrameStrings } from '../../core/hooks/useTimeframe';
import {
  useGetAllMachineAssignmentsQuery,
  useGetAllMachinesQuery,
} from '../../shared/redux/machine';
import './style.scss';
import { Machine } from '../../shared/types/machine';
import { selectFromResult } from '../../shared/logic/defaultArray';
import { arrayEq } from '../../shared/logic/arrayEq';

export const MachinePage = () => {
  const [selectedMachine, setSelectedMachine] = useState<{ id: string, title: string } | null>(null);
  const leftMainCat = useSelector((l) => l.leftFilterState.machine?.mainCategories ?? [], arrayEq);
  const leftSubCat = useSelector((l) => l.leftFilterState.machine?.subCategories ?? [], arrayEq);
  const leftSortFilter = useSelector((l) => l.leftFilterState.machine.sortingFilter ?? [], arrayEq);
  const leftManagerFilter = useSelector((s) => s.leftFilterState.managers ?? [], arrayEq);
  const showOrdersWithActiveProjectFilter = useSelector((s) => s.viewSetting.showOrdersWithActiveProjectFilter);

  const dispatch = useDispatch();
  const timeFrame = useTimeFrameStrings();
  const organizations = useOrganizations();
  const {
    data: rawMachines,
    isLoading: machinesLoading,
  } = useGetAllMachinesQuery(undefined, {
    selectFromResult,
  });
  const {
    data: assignments,
    isLoading: assignmentsLoading,
    isFetching: assignmentsFetching,
    refetch: refetchAssignments,
  } = useGetAllMachineAssignmentsQuery(
    { organizations, ...timeFrame, filterOrders: !showOrdersWithActiveProjectFilter },
    { pollingInterval: 30000, selectFromResult },
  );

  const combinedLoading = machinesLoading || assignmentsLoading || assignmentsFetching;

  const machines = useMemo((): Machine[] => {
    const rawIds = new Set(rawMachines.map((m) => m.internalNumber));
    const assignmentMachines = assignments
      .map((a) => a.machine)
      .filter((am) => (am && !rawIds.has(am.internalNumber)));
    return [
      ...rawMachines,
      ...assignmentMachines,
    ];
  }, [rawMachines, assignments]);

  useEffect(() => {
    dispatch(setSubCategories([]));
  }, [leftMainCat]);

  const plannerMachines = useMemo(() => {
    const machine = machines
      ?.filter((m) => leftMainCat.length === 0 || leftMainCat.some((mc) => m.mainCategoryName === mc.label))
      ?.filter((m) => leftSubCat.length === 0 || leftSubCat.some((mc) => m.subCategoryName === mc.label))
      ?.filter((m) => (leftSortFilter.length === 0 || leftSortFilter.some((mc) => (m.sorting === '' && mc.label === 'Ingen sortering' ? true : m.sorting === mc.label))))
      ?.map((m) => ({
        ...m,
      })) || [];

    return [...machine];
  }, [machines, leftMainCat, leftSubCat, leftSortFilter]);

  const plannerAssignments = useMemo(() => {
    if (!assignments) return [];

    let filteredAssignments = [...assignments]
      .filter((a) => leftManagerFilter.length === 0
    || leftManagerFilter.some((m) => m.id === a.project?.operationsManager?.employeeNumber));
    if (leftMainCat.length > 0) {
      filteredAssignments = filteredAssignments.filter((a) => (
        a.type === 'Assignment' ? true : leftMainCat.some((mc) => a.category === mc.label)
      ));
    }
    return filteredAssignments;
  }, [assignments, leftMainCat, leftSubCat, leftManagerFilter]);

  const mainCategories = useMemo(() => {
    const items = new Set<string>();
    machines
      ?.filter((m) => leftSubCat.length === 0 || leftSubCat.some((sc) => m.subCategoryName === sc.label))
      ?.forEach((m) => {
        if (m.mainCategoryName) {
          items.add(m.mainCategoryName);
        }
      });
    return Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
  }, [machines, leftSubCat]);

  const subCategories = useMemo(() => {
    const items = new Set<string>();
    machines
      ?.filter((m) => leftMainCat.length === 0 || leftMainCat.some((mc) => m.mainCategoryName === mc.label))
      ?.forEach((m) => {
        if (m.subCategoryName) {
          items.add(m.subCategoryName);
        }
      });
    return Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
  }, [machines, leftMainCat]);

  const sortingFilter = useMemo(() => {
    const items = new Set<string>();
    machines
      ?.forEach((m) => {
        if (m.sorting) {
          items.add(m.sorting);
        }
      });
    const ret = Array.from(items, (i) => ({ id: i, label: i })).sort(labelSort);
    ret.unshift({ id: 'Ingen sortering', label: 'Ingen sortering' });
    return ret;
  }, [machines, leftSortFilter]);

  const projectManagers = useMemo(() => {
    const names = new Map<number, string>();
    assignments?.forEach((a) => {
      if (a.project?.operationsManager) {
        names.set(
          a.project.operationsManager.employeeNumber,
          a.project.operationsManager.fullName,
        );
      }
    });
    return Array.from(names, ([id, label]) => ({ id, label })).sort(labelSort);
  }, [assignments]);

  return (
    <Page className="transportplanlegger-page">
      <Header page="machine" />
      <Planner
        allMachines={machines}
        machines={plannerMachines}
        loading={combinedLoading}
        assignments={plannerAssignments}
        refetchAssignments={refetchAssignments}
        onLeftSelect={(id, title) => setSelectedMachine({ id, title })}
        leftFilters={(
          <Box display="flex" flexDirection="column" gap={2}>
            <span>
              <MultiSearchSelect
                label="Driftsleder"
                size="small"
                value={leftManagerFilter}
                onChange={(v) => dispatch(setManagerFilter(v))}
              >
                {projectManagers}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="Hovedkategori"
                size="small"
                value={leftMainCat}
                onChange={(l) => dispatch(setMainCategories(l))}
              >
                {mainCategories}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="Underkategori"
                size="small"
                value={leftSubCat}
                onChange={(l) => dispatch(setSubCategories(l))}
              >
                {subCategories}
              </MultiSearchSelect>
            </span>
            <span>
              <MultiSearchSelect
                label="Sortering"
                size="small"
                value={leftSortFilter}
                onChange={(l) => dispatch(setSortingFilter(l))}
              >
                {sortingFilter}
              </MultiSearchSelect>
            </span>
          </Box>
        )}
      />

      {selectedMachine !== null && (
        <Modal
          title={(
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {selectedMachine.title} -
              <a target="_blank" href={`https://mm2.barde.app/#/machine/${selectedMachine.id}`} rel="noreferrer">
                Teknisk
              </a>
            </Box>
          )}
          onClose={() => setSelectedMachine(null)}
        >
          <MachineDetails id={selectedMachine.id} />
        </Modal>
      )}
    </Page>
  );
};
