import {
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullC from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, {
  EventDragStartArg,
  EventReceiveArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import nbLocale from '@fullcalendar/core/locales/nb';
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { ColSpec } from '@fullcalendar/resource';
import {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventInput,
  EventContentArg,
  EventDropArg,
} from '@fullcalendar/core';
import {
  addHours,
  addYears,
  endOfYear,
  format,
  formatISO,
  isAfter,
  startOfDay,
  startOfYear,
  subYears,
} from 'date-fns';
import { toast } from 'react-toastify';
import { Displayable } from '../../../shared/types/util/displayable';
import { TimelineHeader } from './partials/timelineHeader';
import { useView } from '../../../core/hooks/useView';
import { useDate } from '../../../core/hooks/useDate';
import { useDraggable } from '../hooks/useDraggable';
import { useColumns, useOrganizations } from '../../../core/redux/leftFilterState';
import { useDefaultUrlParams } from '../hooks/useDefaultUrlParams';
import { useTimeframe, useTimeFrameStrings } from '../../../core/hooks/useTimeframe';
import { LeftHeader } from './partials/leftHeader';
import { translateColumnName } from '../../../core/helpers/translate';
import { PRIMARY, UNDER_PLANNING_COLOR } from '../../../shared/constants';
import { HoverEvent } from '../../../components/hoverEvent';
import { Machine } from '../../../shared/types/machine';
import { Assignment } from '../../../shared/types/assignment';
import {
  useGetAllProjectsQuery,
  usePutApproveOrderBodyMutation,
  usePutMoveMachineAssignmentMutation,
  usePutMoveMachineOrderMutation,
  useGetAllAbsencesQuery,
  usePostCopyAssignmentMutation,
  useGetAllUnavailabilitiesQuery,
  usePutUnavailabilityMutation,
  usePutAbsenceMutation,
} from '../../../shared/redux/machine';
import { MoveMachineOrder } from '../../../shared/types/api/moveMachineOrder';
import { useHasRoles } from '../../../core/helpers/useHasRoles';
import { MachineOrderModal } from '../../../shared/components/machineOrderModal';
import { Modal } from '../../../shared/components/modal';
import { ApproveOrder } from '../../../shared/types/api/approveOrder';
import { MoveMachineAssignment } from '../../../shared/types/api/moveMachineAssignment';
import { MachineOrderStatusIcon } from '../../../shared/components/machineOrderStatusIcon';
import { eventifyAbsence, eventifyUnavailability } from '../../../core/helpers/eventify';
import { WorkerDetails } from '../../../components/workerDetails';
import { editMachineRules } from '../../../core/helpers/functions';
import { Unavailability } from '../../../shared/types/unavailability';
import { EditUnavailability } from '../../../components/editUnavailability';
import { NewUnavailability } from '../../../shared/types/api/newUnavailability';
import { Absence } from '../../../shared/types/absence';
import { EditAbsence } from '../../../components/editAbsence';
import { Resource } from '../../../core/types/resource';
import { useSearchFilter } from '../../../core/hooks/useSearchFilter';
import { UpdateAbsence } from '../../../shared/types/api/updateAbsence';
import { LastEditInfo } from '../../../shared/components/lastEditInfo';
import { useSelector } from '../../../core/hooks/redux';
import { NewMachineOrderModal } from '../../../components/newMachineOrderModal';
import { EventCommentIcon } from '../../../shared/components/eventCommentIcon';
import { useRenderCellContent } from './hooks/useRenderCellContent';

export const Planner: FC<{
  allMachines: Machine[],
  machines: Machine[],
  loading: boolean,
  assignments: Assignment[],
  leftFilters?: ReactNode,
  onLeftSelect?: (id: string, title: string) => void,
  refetchAssignments: () => void
}> = ({
  allMachines,
  machines,
  loading,
  assignments,
  leftFilters,
  refetchAssignments,
  onLeftSelect = () => null,
}) => {
  useDefaultUrlParams();
  const [date, setDate] = useDate();
  const [view, setView] = useView();
  const timeframe = useTimeframe();
  const timeframeStrings = useTimeFrameStrings();
  const dataColumns = useColumns();
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [contextMenu, setContextMenu] = useState<{
    id: string,
    mouseX: number,
    mouseY: number,
  } | null>(null);
  const [activeEvent, setActiveEvent] = useState<Assignment | null>(null);
  const [driver, setDriver] = useState<{id: number, name: string}|null>(null);
  const [selectedUnavailability, setSelectedUnavailability] = useState<Unavailability | null>(null);
  const [selectedAbsence, setSelectedAbsence] = useState<Absence | null>(null);
  const showWeekend = useSelector((s) => s.viewSetting.showWeekend);
  const showOrdersWithActiveProjectFilter = useSelector((s) => s.viewSetting.showOrdersWithActiveProjectFilter);
  const showOnlyAssignments = useSelector((s) => s.viewSetting.showOnlyAssignments);
  const projectFilter = useSelector((s) => s.viewSetting.projectFilter);
  const organizations = useOrganizations();
  const isAdmin = useHasRoles('maskin-koordinator');
  const canEdit = useHasRoles(['maskin-koordinator', 'prosjekteier']);

  const { data: projects } = useGetAllProjectsQuery();
  const { data: absences } = useGetAllAbsencesQuery(timeframeStrings);
  const { data: unavailabilities } = useGetAllUnavailabilitiesQuery();
  const [updateUnavailability] = usePutUnavailabilityMutation();
  const [approveOrder] = usePutApproveOrderBodyMutation();
  const [changeOrder] = usePutMoveMachineOrderMutation();
  const [changeAssignment] = usePutMoveMachineAssignmentMutation();
  const [postAssignmentCopy] = usePostCopyAssignmentMutation();
  const [updateAbsence] = usePutAbsenceMutation();
  const searchFilter = useSearchFilter();

  const cellContent = useRenderCellContent((e, id, name) => {
    // On driver selected
    e.stopPropagation();
    if (id === undefined) return;
    if (name === undefined) return;

    // if driver is 'Dagsleie', return
    if (id === 9999) return;

    setDriver({
      id,
      name,
    });
  });

  const calRef: RefObject<FullC> = useRef<FullC>(null);
  const calApi = useMemo(() => (calRef.current ? calRef.current.getApi() : null), [calRef.current]);

  const [isDragging, setIsDragging] = useState<EventApi|null>(null);

  const eventDragStart = (e: EventDragStartArg) => {
    setIsDragging(e.event);
  };

  const eventNoResizeOrDrop = () => {
    setIsDragging(null);
    calApi?.refetchEvents();
  };

  useEffect(() => {
    const els = Object.values(document.getElementsByClassName('fc-scroller fc-scroller-liquid-absolute'));
    if (els.length <= 0) return;
    setTimeout(() => els[0].scrollTo(0, 0), 50);
  }, [showOnlyAssignments]);

  useEffect(() => setFirstRun(false), []);

  useDraggable();

  // Assignments that have been updated, but where the update has not come back from the server
  const [updatedAssignments, setUpdatedAssignments] = useState<Assignment[]>(assignments);

  const modalProjects = useMemo(() => (
    projects?.map((p) => ({ id: p.id, label: p.projectName })) as Displayable[]
  ), [projects]);

  const FCResources = useMemo(() => {
    if (!machines) return [];
    const machineResources = machines
      .map((m): Resource => ({
        ...m,
        id: m.internalNumber,
        title: `${m.internalNumber} - ${m.mainCategoryName}`,
        assignedDriver_fullName: m.assignedDriver?.fullName,
        substituteDriverName: m.substituteDriverName,
        internalNumber: m.internalNumber,
        mainCatSort: m.mainCategoryName,
        subCatSort: m.subCategoryName,
        modelSort: m.modelName,
      }))
      .filter(searchFilter) || [];
    const assignmentResources = assignments
      .filter((a) => a.type === 'Order')
      .map((a): Resource => ({
        id: `order--${a.subCategory}`,
        type: a.type,
        title: a.subCategory,
        mainCategoryName: a.category,
        subCategoryName: a.subCategory,
        sortOrder: '1',
        mainCatSort: a.category,
        subCatSort: a.subCategory,
      })).filter(searchFilter) || [];
    return [
      ...machineResources,
      ...assignmentResources,
    ];
  }, [machines, assignments, searchFilter]);

  const isFilterActive = organizations.length > 0 || projectFilter.length > 0 || showOnlyAssignments;

  const FCColumns = useMemo(() => {
    const cols = dataColumns.map((c): ColSpec => ({
      field: c,
      headerContent: translateColumnName(c),
      // eslint-disable-next-line react/no-unstable-nested-components
      cellContent: (e) => cellContent(c, e),
    }));

    return cols;
  }, [dataColumns, FCResources]);

  const FCAbsences = useMemo(() => eventifyAbsence(absences || [], isAdmin), [absences, isAdmin]);
  const FCUnavailabilities = useMemo(() => eventifyUnavailability(unavailabilities || [], isAdmin), [unavailabilities, isAdmin]);

  const FCEvents = useMemo(() => {
    const timelineBackgroundDummy = (a: Assignment[]) => a.reduce((c, v): EventInput[] => {
      if (c.some((x) => x.resourceId === v.subCategory)) return c;
      return [...c, {
        id: `bg${v.id}`,
        resourceId: `order--${v.subCategory}`,
        start: subYears(startOfYear(new Date()), 1),
        end: addYears(endOfYear(new Date()), 1),
        display: 'background',
        color: 'rgb(215, 215, 215)',
      }];
    }, [] as EventInput[]);

    let internalAssignments = [...updatedAssignments];
    const temp = (() => {
      if (internalAssignments.length === 0) return assignments;

      const t = assignments.map((a) => {
        const updated = internalAssignments.find((x) => x.id === a.id);
        if (!updated) return a;
        if (isAfter(new Date(updated.lastEditTime), new Date(a.lastEditTime))) {
          return updated;
        }

        internalAssignments = internalAssignments.filter((x) => x.id !== a.id);
        return a;
      });

      if (internalAssignments.length !== updatedAssignments.length) setUpdatedAssignments(internalAssignments);
      return t;
    })();

    const resources = new Set<string|undefined>(FCResources.map((r) => r.id));
    const filteredAssignments = temp
      .filter((a) => resources.has(a.machine?.internalNumber) || a.type === 'Order')
      .filter((a) => (showOnlyAssignments ? (a.status !== 'Approved') : a))
      .filter(
        (a) => projectFilter.length === 0
      || (showOrdersWithActiveProjectFilter
        ? a.type === 'Order' || projectFilter.some((p) => a.project.id === p.id)
        : projectFilter.some((p) => a.project.id === p.id)),
      );
    const filteredEvents = filteredAssignments
      .map((a): EventInput => {
        const can = editMachineRules(a, isAdmin, canEdit);
        // prevent change of assignment until we get an update
        const isChanged = !!internalAssignments.find((x) => x.id === a.id);
        return ({
          ...a,
          id: a.id,
          title: a.project.projectName,
          start: new Date(a.from),
          end: new Date(a.to),
          resourceId: a.type === 'Assignment' ? a.machine?.internalNumber : `order--${a.subCategory}`,
          parent: a.type === 'Assignment' ? a.machine?.internalNumber : a.subCategory,
          origin: a.project.id.toString(),
          borderColor: a.status === 'Approved' ? PRIMARY : UNDER_PLANNING_COLOR,
          backgroundColor: a.status === 'Approved' ? PRIMARY : UNDER_PLANNING_COLOR,
          textColor: 'white',
          editable: false,
          startEditable: isAdmin && !isChanged,
          resourceEditable: can.editMachine && !isChanged,
          durationEditable: can.editTime && !isChanged,
        });
      });

    const filteredEventResourceIds = new Set<string|undefined>(
      filteredEvents.map((r) => r.resourceId),
    );
    const filterFn = isFilterActive
      ? (e: EventInput) => filteredEventResourceIds.has(e.resourceId)
      : () => true;
    return [
      ...filteredEvents,
      ...timelineBackgroundDummy(filteredAssignments.filter((a) => a.type === 'Order')),
      ...FCAbsences.filter(filterFn),
      ...FCUnavailabilities.filter(filterFn),
    ];
  }, [assignments, showOnlyAssignments, FCAbsences, FCUnavailabilities, projectFilter, showOrdersWithActiveProjectFilter, FCResources]);

  const [FCEventsCache, setFCEventsCache] = useState<EventInput[]>([]);

  useEffect(() => {
    // prevent updating events while currently dragging or loading
    if (isDragging !== null || loading) {
      return;
    }

    setFCEventsCache(FCEvents);
  }, [FCEvents, isDragging, loading]);

  const leftHeader = useMemo(() => (
    <LeftHeader filters={leftFilters} />
  ), [leftFilters]);

  const unavailabilityChanged = (e: EventResizeDoneArg | EventDropArg | EventReceiveArg) => {
    if (!e.event.start || !e.event.end) return;
    const unavailability = unavailabilities?.find((u) => u.id === e.event.id);
    if (!unavailability) return;
    const body: NewUnavailability = {
      from: formatISO(e.event.start),
      to: formatISO(e.event.end),
      comment: unavailability.comment,
    };
    updateUnavailability({
      machineId: unavailability.machine.internalNumber || unavailability.machineInternalNumber,
      id: unavailability.id,
      body,
    }).unwrap().finally(() => setIsDragging(null));
  };

  const absenceChanged = (e: EventResizeDoneArg | EventDropArg | EventReceiveArg) => {
    if (!e.event.start || !e.event.end) return;
    const absence = absences?.find((a) => a.id === parseInt(e.event.id, 10));
    if (!absence) return;
    const body: UpdateAbsence = {
      startTime: formatISO(e.event.start),
      endTime: formatISO(e.event.end),
      comment: absence.comment,
      title: '',
    };
    updateAbsence({
      id: absence.id,
      workerId: absence.driver.employeeNumber,
      body,
    }).unwrap().finally(() => setIsDragging(null));
  };

  const eventChanged = (e: EventResizeDoneArg | EventDropArg | EventReceiveArg) => {
    if (!e || !e?.event) return;
    if (e.event.extendedProps.isAbsence) {
      absenceChanged(e);
      return;
    }

    const machineOrder = assignments.find((a) => a.id === e.event.id);
    if (!machineOrder) {
      unavailabilityChanged(e);
      return;
    }
    // assignments cant be placed on dummy resources (order row)
    if (machineOrder.type !== 'Order' && e.event.getResources()[0].extendedProps.type === 'Order') {
      e.revert();
      setIsDragging(null);
      toast.error('Ugyldig flytting av tildeling');
      return;
    }
    if (e.event.start && e.event.end) {
      // If the user is not an admin, they might still be able to update events.
      // In this case the update must be sent as an order update.
      if (machineOrder.type === 'Order' || !isAdmin) {
        const resourceId = e.event.getResources()[0].id;
        const orderBody: MoveMachineOrder = {
          from: formatISO(e.event.start),
          to: formatISO(e.event.end),
          category: machineOrder.category,
          subCategory: machineOrder.subCategory,
          machineInternalNumber: resourceId.startsWith('order--') ? undefined : resourceId,
          projectId: machineOrder.project.id,
          comment: machineOrder.orderComment,
        };
        const id = machineOrder.type === 'Order'
          ? e.event.id
          : e.event.extendedProps?.order?.id;
        if (id) {
          changeOrder({ id, body: orderBody })
            .then((response) => {
              if ('data' in response) {
                setUpdatedAssignments([...updatedAssignments, response.data]);
              }
              setIsDragging(null);
            });
          return;
        }
      }
    }
    if (e.event.start && e.event.end && e.event.getResources()[0].id && machineOrder.type === 'Order') {
      const approvebody: ApproveOrder = {
        from: formatISO(e.event.start),
        to: formatISO(e.event.end),
        projectId: machineOrder.project.id,
        machineInternalNumber: e.event.getResources()[0].id,
        comment: '',
      };
      approveOrder({ id: e.event.id, body: approvebody })
        .then((response) => {
          if ('data' in response) {
            setUpdatedAssignments([...updatedAssignments, response.data]);
          }
          setIsDragging(null);
        });
    }

    if (!e.event.getResources()[0].id.startsWith('order--') && e.event.start && e.event.end) {
      if (machineOrder.type === 'Assignment') {
        const assignmentBody: MoveMachineAssignment = {
          from: formatISO(e.event.start),
          to: formatISO(e.event.end),
          projectId: machineOrder.project.id,
          machineInternalNumber: e.event.getResources()[0].id,
          comment: machineOrder.comment,
        };
        changeAssignment({ id: e.event.id, body: assignmentBody })
          .unwrap()
          .then((assignment) => {
            setUpdatedAssignments([...updatedAssignments.filter((a) => a.id !== assignment.id), assignment]);
          }).catch(() => {
            toast.error('Kunne ikke tildele maskin.');
          })
          .finally(() => {
            setIsDragging(null);
          });
      }
    }
  };

  const eventReceive = (e: EventReceiveArg) => {
    // If context changes between start of operation and end of operation
    // we get an receieve event instead. Handle this as an update of existing
    // event.
    eventChanged(e);
  };

  const copyAssignment = (id: string|undefined) => {
    setContextMenu(null);
    if (id === undefined) return;
    postAssignmentCopy(id).unwrap()
      .catch(() => {
        toast.error('Kunne ikke kopiere tildeling');
      });
  };

  const handleContextMenu = useCallback((event: MouseEvent, id: string|undefined) => {
    event.preventDefault();
    if (id === undefined) return;
    if (!isAdmin) return;
    if (!assignments.some((a) => a.id === id && a.type === 'Assignment')) return;

    setContextMenu({ id, mouseX: event.clientX + 2, mouseY: event.clientY - 6 });
  }, [isAdmin, setContextMenu, assignments]);

  const eventContent = useCallback((e: EventContentArg) => {
    const event = assignments.find((a) => a.id === e.event.id);
    let title: string|ReactNode|null = null;
    let isComment: boolean = false;
    let whiteArrow: boolean = false;
    let tooltipContent: ReactNode = null;
    if (event) {
      title = `${event.project.id} - ${event.project.projectName} - ${event.type === 'Order' ? event.createdByName : event.order.createdByName}`;
      isComment = !!event?.orderComment || !!event?.comment;
      whiteArrow = true;
      tooltipContent = (event ? (
        <HoverEvent
          task={event}
        />
      ) : (
        ''
      ));
    } else if (e.event.extendedProps.isAbsence) {
      const absence = absences?.find((a) => a.id === parseInt(e.event.id, 10));
      if (absence?.type === 'Absence') {
        title = (<Box fontWeight="bold">Avtalt Fravær</Box>);
      } else {
        title = absence?.sickPercentage ? `Fravær ${absence.sickPercentage}%` : 'Fravær';
      }
      isComment = !!absence?.comment;
      tooltipContent = (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <span className="bold font-size-12"> {absence?.type === 'Absence' ? absence.comment : absence?.title}</span>
          {absence?.startTime && absence.endTime ? (
            <span>{`Tidsrom: ${format(new Date(absence.startTime), 'dd.MM.yyyy')} - ${format(new Date(absence.endTime), 'dd.MM.yyyy')}`}</span>
          ) : (
            null
          )}
        </Box>
      );
    } else {
      const unavailability = unavailabilities?.find((u) => u.id === e.event.id);
      if (unavailability) {
        title = e.event.title;
        isComment = !!unavailability.comment;
        tooltipContent = (
          <Box display="flex" flexDirection="column" gap={1.5}>
            <span className="bold font-size-12"> {title} </span>
            {unavailability?.from && unavailability.to ? (
              <span>{`Tidsrom: ${format(new Date(unavailability.from), 'dd.MM.yyyy')} - ${format(new Date(unavailability.to), 'dd.MM.yyyy')}`}</span>
            ) : (
              null
            ) }
            <span>{unavailability.comment}</span>
            <Box> <LastEditInfo data={unavailability} darkMode /> </Box>
          </Box>
        );
      } else {
        title = e.event.title;
        tooltipContent = e.event.title;
      }
    }
    return (
      <Tooltip
        title={tooltipContent}
        followCursor
        placement="right-start"
        enterDelay={1000}
        enterNextDelay={1000}
        arrow
      >
        <div className={`fc-event-main-frame ${whiteArrow ? 'white-arrow' : ''}`}>
          { isComment && (
            <EventCommentIcon />
          )}
          <span className="fc-event-title fc-sticky">
            {title}
          </span>
        </div>
      </Tooltip>
    );
  }, [machines, assignments, absences, unavailabilities]);

  const eventClicked = useCallback((e: EventClickArg) => {
    const clickedAssignment = assignments?.find((a) => a.id === e.event.id);
    if (clickedAssignment) {
      return setActiveEvent(clickedAssignment);
    }
    const clickedUnavailability = unavailabilities?.find((u) => u.id === e.event.id);
    if (clickedUnavailability) {
      return setSelectedUnavailability(clickedUnavailability);
    }
    const clickedAbsence = absences?.find((a) => a.id === parseInt(e.event.id, 10));
    if (clickedAbsence && clickedAbsence.type === 'Absence') {
      return setSelectedAbsence(clickedAbsence);
    }
    return null;
  }, [assignments, unavailabilities, absences]);

  const [newAssignment, setNewAssignment] = useState<{fromDate: Date, toDate: Date, machine: Machine} | null>(null);

  const addAssignment = (a: DateSelectArg) => {
    // Clear selection
    calRef.current?.getApi()?.unselect();

    const machine = machines.find((m) => m.internalNumber === a.resource?.id);
    if (!machine) return;

    const fromDate = addHours(startOfDay(a.start), 7);
    const toDate = addHours(startOfDay(a.end), 15 - 24);
    setNewAssignment({
      fromDate,
      toDate,
      machine,
    });
  };

  return (
    <Box className="full-calendar-wrapper">
      <div className={`calendar-wrapper${loading ? ' loading' : ''}`}>
        <TimelineHeader
          day={date}
          currentView={view}
          onDayChange={(d) => { setDate(d); calApi?.gotoDate(date); }}
          onCalendarViewChange={(v) => { setView(v); calApi?.changeView(v); }}
        />
        <div className={`calendar-inner${view === 'kvartal' || view === 'ar' ? ' fc-hide-lines' : ''}`}>
          <FullC
            ref={calRef}
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            selectable
            select={addAssignment}
            initialView={view}
            initialDate={date}
            events={FCEventsCache}
            eventContent={eventContent}
            eventDragStart={eventDragStart}
            eventResizeStart={eventDragStart}
            eventDrop={eventChanged}
            eventResize={eventChanged}
            eventReceive={eventReceive}
            _noEventDrop={eventNoResizeOrDrop}
            _noEventResize={eventNoResizeOrDrop}
            eventDidMount={(arg) => {
              arg.el.addEventListener('contextmenu', (jsEvent) => {
                jsEvent.preventDefault();
                jsEvent.stopPropagation();
                handleContextMenu(jsEvent, arg.event.id);
              });
            }}
            rerenderDelay={50}
            resources={FCResources}
            filterResourcesWithEvents={isFilterActive || showOnlyAssignments}
            eventClick={eventClicked}
            resourceAreaColumns={FCColumns}
            resourceAreaHeaderContent={leftHeader}
            resourceOrder="sortOrder, mainCatSort, subCatSort, internalNumber, modelSort, assignedDriver_fullName"
            resourceLabelDidMount={({ resource, el }) => {
              if (!el?.parentElement) return;
              if (resource.id.startsWith('order--')) {
                // eslint-disable-next-line no-param-reassign
                el.parentElement.className = 'dummy';
              } else {
                // eslint-disable-next-line no-param-reassign
                el.parentElement.onclick = () => (
                  onLeftSelect(resource.id, resource.title)
                );
              }
            }}
            headerToolbar={false}
            nowIndicator
            droppable
            editable
            height="100%"
            eventResourceEditable
            resourceAreaWidth={300 + (dataColumns.length * 50)}
            locale={nbLocale}
            scrollTimeReset={false}
            displayEventTime={false}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4, 5],
              startTime: '07:00',
              endTime: '15:00',
            }}
            visibleRange={firstRun ? undefined : {
              start: timeframe.from,
              end: timeframe.to,
            }}
            slotLabelClassNames="slot-label"
            views={{
              maned: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                snapDuration: { days: 1 },
                slotLabelFormat: [{ week: 'long' }, { weekday: 'short', day: '2-digit' }],
                slotLabelContent: (l) => (
                  l.level !== 1
                    ? l.text
                    : l.text.slice(0, 2) + l.text.slice(3, l.text.length).replace(/\.$/, '')
                ),
                slotMinWidth: 52.5,
                weekends: showWeekend,
              },
              kvartal: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                snapDuration: { days: 1 },
                slotLabelFormat: [{ month: 'long' }, { week: 'long' }],
                slotLabelInterval: { days: 7 },
                slotMinWidth: 10,
                weekends: showWeekend,
                slotLabelContent: (l) => l.text,
              },
              ar: {
                type: 'resourceTimeline',
                slotDuration: { days: 1 },
                slotLabelFormat: [{ month: 'long' }, { week: 'numeric' }],
                slotLabelInterval: { days: 7 },
                slotMinWidth: 5,
                weekends: showWeekend,
                slotLabelContent: (l) => l.text,
              },
            }}
            // Not a secret since it can easily be spotted in minified files
            // no matter where or how it is stored
            schedulerLicenseKey="0067403127-fcs-1701517614"
          />
        </div>
      </div>
      {loading && (
      <CircularProgress
        size={50}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
          zIndex: 1000,
        }}
      />
      )}
      {activeEvent !== null && (
        <Modal
          title={`${activeEvent.type === 'Assignment' ? 'Maskintildeling' : 'Maskinbestilling'} - ${activeEvent.project.projectName}`}
          compactHeader
          statusIcon={<MachineOrderStatusIcon currStatus={activeEvent.status} type={activeEvent.type} />}
          onClose={() => setActiveEvent(null)}
        >
          <MachineOrderModal
            order={activeEvent}
            can={editMachineRules(activeEvent, isAdmin, canEdit)}
            projects={modalProjects}
            machines={allMachines}
            isEdit
            refetch={refetchAssignments}
            onClose={() => setActiveEvent(null)}
          />
        </Modal>
      )}
      {driver !== null && (
        <Modal
          title={`${driver.id} ${driver.name}`}
          compactHeader
          onClose={() => setDriver(null)}
        >
          <WorkerDetails id={driver.id} isAdmin={isAdmin} />
        </Modal>
      )}
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
      >
        <MenuItem onClick={() => copyAssignment(contextMenu?.id)}>Kopier</MenuItem>
        <MenuItem onClick={() => setContextMenu(null)}>Avbryt</MenuItem>
      </Menu>
      { selectedUnavailability && (
        <Modal
          title="Endre utilgjengelighet"
          onClose={() => setSelectedUnavailability(null)}
        >
          <EditUnavailability
            machineId={selectedUnavailability?.machine.internalNumber || ''}
            id={selectedUnavailability?.id}
            onAction={() => setSelectedUnavailability(null)}
          />
        </Modal>
      )}

      { selectedAbsence && (
        <Modal
          title={`Opprett fravær for ${selectedAbsence.driver.fullName} (${selectedAbsence.driver.employeeNumber})`}
          maxWidth="sm"
          onClose={() => setSelectedAbsence(null)}
        >
          <EditAbsence
            userId={selectedAbsence.driver.employeeNumber}
            absence={selectedAbsence}
            onClose={() => setSelectedAbsence(null)}
            isAdmin={isAdmin}
          />
        </Modal>
      )}
      <Modal
        open={newAssignment !== null}
        title="Ny maskinbestilling"
        onClose={() => setNewAssignment(null)}
      >
        <NewMachineOrderModal
          onClose={() => setNewAssignment(null)}
          fromDate={newAssignment?.fromDate}
          toDate={newAssignment?.toDate}
          machine={newAssignment?.machine}
        />
      </Modal>
    </Box>
  );
};
