import { FC, useState } from 'react';
import { UnavailabilityModal } from '../unavailabilityModal';
import { Guid } from '../../shared/types/guid';
import { useGetUnavailabilitiesQuery } from '../../shared/redux/machine';
import { TimeframeView } from '../timeframeView';
import { DateTime } from '../../shared/types/util/dateTime';
import { ListItem } from './partials/listitem';
import './style.scss';

export const UnavailabilityView: FC<{
  id: Guid,
  currentDate: Date,
  onClose?: () => void;
}> = ({
  id,
  currentDate,
  onClose,
}) => {
  const [editUnavailability, setEditUnavailability] = useState<boolean>(false);
  const [unavailabilityId, setunavailabilityId] = useState<Guid>('');

  const dataHook = (range: {start?: DateTime, end?: DateTime}) => useGetUnavailabilitiesQuery({ id, ...range });

  return (
    <div>
      <TimeframeView
        currentDate={currentDate}
        dataHook={dataHook}
        onClose={onClose}
        onAdd={() => {
          setEditUnavailability(true);
          setunavailabilityId('');
        }}
        filterFunction={(u, search) => (
          u.comment.toLowerCase().includes(search)
        )}
        renderItem={(s) => (
          <ListItem
            key={s.id}
            data={{
              ...s,
            }}
            onClick={() => {
              setEditUnavailability(true);
              setunavailabilityId(s.id);
            }}
          />
        )}
        noItemsLabel="Ingen utilgjengeligheter funnet"
        searchLabel="Søk utilgjengeligheter"
      />
      {editUnavailability && (
      <UnavailabilityModal
        internalNumber={id}
        currentDate={currentDate}
        isEdit
        unavailabilityId={unavailabilityId}
        title={unavailabilityId ? 'Endre utilgjengelighet' : 'Opprett utilgjengelighet'}
        onClose={() => { setunavailabilityId(''); setEditUnavailability(false); }}
      />
      )}
    </div>
  );
};
