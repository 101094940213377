/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SelectItem } from '../../shared/types/util/selectItem';

const stateName = 'leftFilterState';

const machineColumns = [
  'mainCategoryName',
  'subCategoryName',
  'internalNumber',
  'vehicleRegistrationPlateNumber',
  'modelName',
  'assignedDriver_fullName',
  'operatingTime',
  'gpsMake',
  'sorting',
  'commentMP',
  'comment',
] as const;
export type MachineColumn = typeof machineColumns[number];

interface InitialState {
  machine: {
    columns: Array<MachineColumn>,
    mainCategories: SelectItem[],
    subCategories: SelectItem[],
    sortingFilter: SelectItem[],
  },
  managers: SelectItem[]
  defaultSet: boolean,
  organizations: Array<number>,
  searchString: string,
}

const initialState: InitialState = {
  machine: {
    columns: ['subCategoryName', 'modelName', 'assignedDriver_fullName'],
    mainCategories: [],
    subCategories: [],
    sortingFilter: [],
  },
  managers: [],
  defaultSet: false,
  organizations: [],
  searchString: '',
};

const leftFilterState = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setColumns(state, { payload: { machine } }: PayloadAction<{machine: MachineColumn[]}>) {
      state.machine.columns = machine.filter((c) => machineColumns.includes(c));
    },
    setMainCategories(state, { payload: mainCategories }: PayloadAction<SelectItem[]>) {
      state.machine.mainCategories = mainCategories;
    },
    setSubCategories(state, { payload: subCategories }: PayloadAction<SelectItem[]>) {
      state.machine.subCategories = subCategories;
    },
    setSortingFilter(state, { payload: sortingFilter }: PayloadAction<SelectItem[]>) {
      state.machine.sortingFilter = sortingFilter;
    },
    setManagerFilter(state, { payload: managers }: PayloadAction<SelectItem[]>) {
      state.managers = managers;
    },
    setOrganizations(state, { payload: organizations }: PayloadAction<number[]>) {
      state.organizations = organizations;
    },
    setSearchString(state, { payload: searchString }: PayloadAction<string>) {
      state.searchString = searchString;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

const sortOrder: MachineColumn[] = [
  'mainCategoryName',
  'subCategoryName',
  'internalNumber',
  'vehicleRegistrationPlateNumber',
  'modelName',
  'assignedDriver_fullName',
  'operatingTime',
  'gpsMake',
  'sorting',
  'commentMP',
  'comment',
];

export const useColumns = () => {
  const state = useSelector((s: {[stateName]: InitialState}) => s[stateName].machine.columns);
  const sorted = useMemo(() => {
    const cols = [...state].filter((c) => machineColumns.includes(c));
    cols.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    return cols;
  }, [state]);
  return sorted;
};

export const useOrganizations = () => {
  const organizations = useSelector((s: {[stateName]: InitialState}) => s[stateName].organizations);
  return organizations;
};

export const useColumnNames = () => machineColumns;

export const {
  setColumns,
  setMainCategories,
  setSubCategories,
  setSortingFilter,
  setManagerFilter,
  setOrganizations,
  setSearchString,
  reset,
} = leftFilterState.actions;

export default persistReducer({ key: stateName, storage }, leftFilterState.reducer);
