import React, {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  addHours,
  formatISO,
  isBefore,
  roundToNearestMinutes,
  startOfDay,
} from 'date-fns';
import {
  Box,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '../../shared/components/dateTimePicker';
import { Absence } from '../../shared/types/absence';
import { PopConfirm } from '../../shared/components/popConfirm';
import { UpdateAbsence } from '../../shared/types/api/updateAbsence';
import { NOT_DELIVERED_COLOR } from '../../shared/constants';
import { ensureAfter } from '../../shared/logic/dates';
import { Button } from '../../shared/components/button';
import { useDeleteAbsenceMutation, usePostAbsenceMutation, usePutAbsenceMutation } from '../../shared/redux/machine';

export const EditAbsence: FC<{
  absence?: Absence|undefined,
  userId?: number,
  onClose?: () => void,
  startDate?: Date,
  isAdmin?: boolean,
}> = ({
  absence,
  userId,
  isAdmin = false,
  onClose = () => null,
  startDate = new Date(),
}) => {
  const [from, setFrom] = useState<Date>(addHours(startOfDay(startDate), 7));
  const [to, setTo] = useState<Date>(addHours(startOfDay(startDate), 15));
  const [comment, setComment] = useState<string>('');

  const [sendCreate, { isLoading: isCreating }] = usePostAbsenceMutation();
  const [sendUpdate, { isLoading: isUpdating }] = usePutAbsenceMutation();
  const [sendDelete, { isLoading: isDeleting }] = useDeleteAbsenceMutation();

  const loading = useMemo(
    () => isDeleting || isUpdating || isCreating,
    [isDeleting, isUpdating, isCreating],
  );

  useEffect(() => {
    if (!absence) return;
    setFrom(roundToNearestMinutes(new Date(absence.startTime), { nearestTo: 15 }));
    setTo(roundToNearestMinutes(new Date(absence.endTime), { nearestTo: 15 }));
    setComment(absence.comment || '');
  }, [absence]);

  const changeFrom = (date: Date) => {
    setFrom(date);
    setTo((old) => ensureAfter(old, date));
  };

  const deleteAbsence = () => {
    if (!absence) return;
    if (!absence.driver) return;
    sendDelete({ workerId: absence.driver?.employeeNumber, id: absence.id }).unwrap().then(() => {
      onClose();
    });
  };

  const createAbsence = () => {
    if (absence !== undefined || userId === undefined) return;
    const body: UpdateAbsence = {
      title: '',
      startTime: formatISO(from),
      endTime: formatISO(to),
      comment,
    };
    sendCreate({ id: userId, body }).unwrap().then(() => {
      onClose();
    });
  };

  const updateAbsence = () => {
    if (!absence) return;
    if (!absence.driver) return;
    const body: UpdateAbsence = {
      title: '',
      startTime: formatISO(from),
      endTime: formatISO(to),
      comment,
    };
    sendUpdate({ workerId: absence.driver?.employeeNumber, id: absence.id, body }).unwrap().then(() => {
      onClose();
    });
  };

  const isValid = useMemo(() => {
    if (isBefore(from, to)) return true;
    return false;
  }, [from, to]);
  return (
    <div className="edit-absence-component">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              size="medium"
              fullWidth
              label="Fra"
              value={from}
              onChange={changeFrom}
              defaultTime={7}
              disableTime
              disabled={!isAdmin}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              size="medium"
              fullWidth
              label="Til"
              value={to}
              onChange={setTo}
              minDate={from}
              defaultTime={15}
              disableTime
              disabled={!isAdmin}
            />
          </Box>
        </Box>
        <Box>
          <TextField
            label="Kommentar"
            fullWidth
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            minRows={4}
            disabled={!isAdmin}
          />
        </Box>
        {!isValid && (
          <Typography color={NOT_DELIVERED_COLOR} align="right">Fra tid må være før til tid</Typography>
        )}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            {absence !== undefined && isAdmin && (
              <PopConfirm
                buttonProps={{ disabled: !isDeleting && loading }}
                loading={isDeleting}
                content={(
                  <>
                    Vil du slette dette fraværet?<br />
                    Det vil ikke være mulig å angre slettingen
                  </>
                )}
                onConfirm={deleteAbsence}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button color="primary" variant="outlined" disabled={loading} onClick={onClose}>{isAdmin ? 'Avbryt' : 'Lukk'}</Button>
            { isAdmin && (
            <Button
              disabled={!isValid || (!(isCreating || isUpdating) && loading)}
              loading={isCreating || isUpdating}
              color="primary"
              variant="contained"
              onClick={absence === undefined ? createAbsence : updateAbsence}
            >Lagre
            </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
