import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { loggerMiddleware } from './loggerMiddleware';
import machine, { machineApi } from '../../shared/redux/machine';
import version, { versionApi } from '../../shared/redux/version';
import tbn, { tbnApi } from './tbn';
import leftFilterState from './leftFilterState';
import viewSetting from './viewSetting';
import rentalOrderState from '../../shared/redux/local/rentalOrderState';

// Put additional reducers here
export const store = configureStore({
  reducer: {
    rentalOrderState,
    leftFilterState,
    machine,
    version,
    viewSetting,
    tbn,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(machineApi.middleware, versionApi.middleware, tbnApi.middleware)
    .concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
