import { Assignment } from '../../shared/types/assignment';
import { MachineEditRules, machineEditRulesDefaults } from '../../shared/types/machineEditRules';
import { SelectItem } from '../../shared/types/util/selectItem';

/**
 * Converts nested objects into attributes
 *
 * @param attributes Attributes to process
 * @param prefix Prefix for attributes
 * @returns Object with attribute names and values
 */
export const getAttributeSet = (attributes: any, prefix = 'data-'): Record<string, string> => {
  const keys = Object.keys(attributes);
  let attribs: Record<string, string> = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = attributes[key];
    if (value === null) {
      attribs[(prefix + key).toLowerCase()] = '';
    } else if (
      typeof value === 'string'
      || typeof value === 'number'
      || typeof value === 'boolean'
    ) {
      attribs[(prefix + key).toLowerCase()] = `${value}`;
    } else if (typeof value === 'object') {
      attribs = { ...attribs, ...getAttributeSet(value, `${prefix}${key}-`) };
    }
  }
  return attribs;
};

/**
 * Gets a list of attributes from a given element
 *
 * @param el Element to get from
 * @param attributes List of attributes to get
 * @param prefix Prefix used to get attributes - does not appear in data
 * @returns Object of attribute name -> attribute value
 */
export const getAttributes = (
  el: { getAttribute: (arg0: string) => any },
  attributes: string[],
  prefix = 'data-',
) => {
  const obj: Record<string, string | null> = {};
  for (let i = 0; i < attributes.length; i += 1) {
    const att = attributes[i];
    obj[att] = el.getAttribute((prefix + att).toLowerCase());
  }
  return obj;
};

export const labelSort = (a: SelectItem, b: SelectItem) => (
  a.label.localeCompare(b.label, 'nb')
);

export const editMachineRules = (event: Assignment, isAdmin: boolean, canEdit: boolean): MachineEditRules => {
  if (event.type === 'Order') {
    return {
      ...machineEditRulesDefaults,
      editTime: canEdit || event.order?.type === 'Change',
      editMachine: canEdit,
      editSubCat: canEdit,

      seeMachine: canEdit,
      seeMainCat: true,
      seeSubCat: true,
      seeComment: true,
      seeOrderComment: true,
      seeLastEditBy: true,

      seeAssignMachineButton: canEdit,
      seeCancelButton: canEdit,
      seeSaveButton: canEdit,

      closeButtonText: isAdmin ? 'Avbryt' : 'Lukk',
      cancelButtonText: isAdmin ? 'Avslå' : 'Kanseller',
    };
  }

  if (event.type === 'Assignment' && event.status === 'Created') {
    return {
      ...machineEditRulesDefaults,
      editTime: canEdit,
      editMachine: isAdmin || (canEdit && event.order.type === 'New'),
      editInternalComment: isAdmin,

      seeMachine: true,
      seeInternalComment: isAdmin,
      seeLastEditBy: true,
      seeOrderCard: isAdmin,

      seeApproveMachineButton: isAdmin,
      seeCancelButton: isAdmin,
      seeSaveButton: isAdmin,

      closeButtonText: isAdmin ? 'Avbryt' : 'Lukk',
      cancelButtonText: isAdmin ? 'Avslå' : 'Kanseller',

    };
  }

  if (event.type === 'Assignment' && event.status === 'Approved') {
    return {
      ...machineEditRulesDefaults,
      editTime: canEdit,
      editMachine: isAdmin,
      editInternalComment: isAdmin,

      seeMachine: true,
      seeInternalComment: isAdmin,
      seeLastEditBy: true,
      seeApprovedBy: true,
      seeOrderCard: isAdmin,

      seeSaveButton: isAdmin,

      seeCancelButton: isAdmin,
      closeButtonText: isAdmin ? 'Avbryt' : 'Lukk',
      cancelButtonText: 'Slett',
    };
  }

  return {
    ...machineEditRulesDefaults,
  };
};
