import { format, isValid } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch } from '../../../core/hooks/redux';
import { setOrganizations } from '../../../core/redux/leftFilterState';
import {
  CalendarView,
  calendarViews,
  view as viewType,
} from '../../../core/types/util/calendarView';
import { useUrl } from '../../../shared/hooks/params';

export const useDefaultUrlParams = () => {
  const dispatch = useDispatch();
  const [{ date: urlDate, view: rawView, orgs }, change, remove] = useUrl();
  const setParams = (params: Record<string, string>) => {
    change('/maskin', params);
  };

  useEffect(() => {
    if (orgs !== undefined) {
      dispatch(setOrganizations(orgs.split(',')
        .map((o) => parseInt(o, 10))
        .filter((o) => o)));
      remove('/maskin', ['orgs']);
    }
    const params: {view?: string, date?: string} = {};
    if (!rawView || !calendarViews.includes(rawView as CalendarView)) {
      params.view = viewType.month;
    }
    if (!urlDate || !isValid(new Date(urlDate))) {
      params.date = format(new Date(), 'yyyy-MM-dd');
    }
    if (Object.keys(params).length > 0) {
      setParams(params);
    }
  }, [rawView]);
};
