import {
  CardActionArea,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import { CallLink } from '../../CallLink';
import { DateTime } from '../../../shared/types/util/dateTime';
import { autoFormat } from '../../../shared/logic/dates';

export const TimeframeViewListItem = ({
  data,
  onClick = () => null,
}: {
  data: {
    id: string,
    name: string,
    start: DateTime,
    end: DateTime,
    phone: string,
  },
  onClick?: (value: string) => void;
}) => (
  <Card
    sx={{ mt: 2, p: 1 }}
  >
    <CardActionArea onClick={() => onClick(data.id)}>
      <div className="p8">
        <div className="line-wrapper">
          <div className="left">
            <PersonIcon className="transform-icon" fontSize="medium" /><span className="sub-name">{data.name}</span>
          </div>
          <span>
            <CallLink phoneNumber={data.phone} className="sub-number" stopPropagation />
          </span>
        </div>
        <div className="sub-period">
          {autoFormat(data.start, data.end)}
        </div>
      </div>
    </CardActionArea>
  </Card>
);
