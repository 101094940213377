import {
  FC,
  useState,
} from 'react';
import { SubstituteModal } from '../substituteModal';
import { Guid } from '../../shared/types/guid';
import { useGetSubstitutesQuery } from '../../shared/redux/machine';
import { TimeframeView, TimeframeViewListItem } from '../timeframeView';
import { DateTime } from '../../shared/types/util/dateTime';

export const SubstituteView: FC<{
  id: Guid,
  currentDate: Date,
  onClose?: () => void;
}> = ({
  id,
  currentDate,
  onClose,
}) => {
  const [editSubstitute, setEditSubstitute] = useState<boolean>(false);
  const [substituteId, setsubstituteId] = useState<Guid>('');

  const dataHook = (range: {start?: DateTime, end?: DateTime}) => useGetSubstitutesQuery({ id, ...range });

  return (
    <div>
      <TimeframeView
        currentDate={currentDate}
        dataHook={dataHook}
        onClose={onClose}
        onAdd={() => {
          setEditSubstitute(true);
          setsubstituteId('');
        }}
        filterFunction={(s, search) => (
          s.driver.phone.includes(search)
          || s.driver.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )}
        renderItem={(s) => (
          <TimeframeViewListItem
            key={s.id}
            data={{
              ...s,
              name: s.driver.name,
              phone: s.driver.phone,
            }}
            onClick={() => {
              setEditSubstitute(true);
              setsubstituteId(s.id);
            }}
          />
        )}
        noItemsLabel="Ingen vikarer funnet"
        searchLabel="Søk vikarer"
      />
      {editSubstitute && (
      <SubstituteModal
        internalNumber={id}
        currentDate={currentDate}
        isEdit
        substituteId={substituteId}
        title={substituteId ? 'Endre vikar' : 'Opprett vikar'}
        onClose={() => { setsubstituteId(''); setEditSubstitute(false); }}
      />
      )}
    </div>
  );
};
