import { Box } from '@mui/material';
import { MouseEvent } from 'react';
import { MissingSecTraining } from '../../../../shared/components/securityTraining/missingSecTraining';
import { ExpiredCert } from '../../../../shared/components/certification/expiredCert';

export const DriverCellContent = ({
  /** Driver name */
  name,
  /** Number of expired certs for driver */
  expiredCerts = 0,
  missingSecTraining = 0,
  onClick = () => null,
}: {
    name: string,
    expiredCerts?: number,
    missingSecTraining?: number,
    onClick?: (e: MouseEvent) => void,
  }) => (
    <Box
      display="flex"
      width="100%"
      minWidth="5px"
      height="24px"
      alignItems="center"
      className="expired-column"
      onClickCapture={onClick}
    >
      <span className="one-line-ellipse">
        {name}
      </span>
      <span style={{ display: 'flex', gap: '10px', marginRight: '5px' }}>
        <ExpiredCert expCert={expiredCerts || 0} />
        <MissingSecTraining expSecTraining={missingSecTraining || 0} />
      </span>
    </Box>
);
