import { FC, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { isBefore } from 'date-fns';
import { Loading } from './Loading';
import { Worker } from '../../../shared/types/worker';
import { Modal } from '../../../shared/components/modal';
import { useDate } from '../../../core/hooks/useDate';
import { EditAbsence } from '../../editAbsence';
import { DataField } from '../../../shared/components/dataField';
import { CallLink } from '../../CallLink';

export const Details: FC<{
    worker: Worker|undefined,
    isLoading: boolean,
    isAdmin?: boolean,
    cardNumber?: string;
  }> = ({
    worker,
    isLoading = false,
    isAdmin = false,
    cardNumber,
  }) => {
    const [absenceModalOpen, setAbsenceModalOpen] = useState<boolean>(false);
    const closeModal = () => setAbsenceModalOpen(false);
    const [date] = useDate();
    if (!worker || isLoading) return <Loading />;

    return (
      <div className="worker-details">
        <div className="flexcenter">
          <DataField
            label="Telefon"
            sx={{ flex: 1 }}
          >
            {
              worker.mobilePhoneWork
                ? <CallLink phoneNumber={worker.mobilePhoneWork} />
                : 'Ingen data'
            }
          </DataField>
          <DataField
            label="Epost"
            sx={{ flex: 1 }}
          >
            {!worker.email ? 'Ingen data' : worker.email}
          </DataField>
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.department ? 'Ingen data' : worker.department}
            label="Avdeling"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.leader ? 'Ingen data' : worker.leader}
            label="Leder"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <div className="flexcenter">
          <TextField
            fullWidth
            value={!worker.jobType ? 'Ingen data' : worker.jobType}
            label="Arbeid"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
          <TextField
            fullWidth
            value={!worker.jobRole ? 'Ingen data' : worker.jobRole}
            label="Rolle"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </div>

        <Box display="flex" justifyContent="flex-end">
          <TextField
            sx={{ maxWidth: '50%' }}
            fullWidth
            value={cardNumber || ' '}
            label="HMS kortnummer"
            variant="standard"
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{ sx: { fontSize: 17 } }}
          />
        </Box>

        {isAdmin && (
          <div className="footer">
            <Button variant="outlined" onClick={() => setAbsenceModalOpen(true)}>Opprett fravær</Button>
          </div>

        )}
        {absenceModalOpen && (
        <Modal title={`Opprett fravær for ${worker.fullName} (${worker.employeeNumber})`} maxWidth="sm" onClose={closeModal}>
          <EditAbsence userId={worker.employeeNumber} onClose={closeModal} startDate={isBefore(date, new Date()) ? new Date() : date} isAdmin={isAdmin} />
        </Modal>
        )}
      </div>
    );
  };
