import { FC } from 'react';
import {
  Box,
} from '@mui/material';
import { Details } from './partials/details';
import { useGetAllCertificationsWorkerQuery, useGetAllDocumentedSecurityTrainingQuery, useGetWorkerQuery } from '../../shared/redux/machine';
import './style.scss';
import { Tabs } from '../../shared/components/tabs';
import { Certifications } from '../../shared/components/certification/certifications';
import { SecurityTrainings } from '../../shared/components/securityTraining/securityTraining';

export const WorkerDetails: FC<{
  id: number,
  isAdmin?: boolean,
}> = ({
  id,
  isAdmin = false,
}) => {
  // oversikt
  const { data: worker, isLoading: workerLoading } = useGetWorkerQuery(id);
  const { data: securityTrainings, isLoading: secsLoading } = useGetAllDocumentedSecurityTrainingQuery(id);

  // kompetanse/kurs
  const { data: certifications, isLoading: certLoading } = useGetAllCertificationsWorkerQuery(id);

  const cardNumber = certifications?.find((c) => c.name === 'HMS kort')?.cardNumber;

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs tabs={[
        {
          id: 1,
          title: 'Oversikt',
          content: <Details worker={worker} cardNumber={cardNumber} isLoading={workerLoading} isAdmin={isAdmin} />,
          default: true,
        },
        {
          id: 2,
          title: 'Kompetanse/Kurs',
          content: <Certifications cert={certifications} isLoading={certLoading} />,
        },
        {
          id: 3,
          title: 'Dok. Sikkerhetsopplæring',
          content: <SecurityTrainings secs={securityTrainings} isLoading={secsLoading} />,
        },
      ]}
      />
    </Box>
  );
};
