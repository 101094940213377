import { FC, SyntheticEvent } from 'react';
import { useMakeCall } from '../../core/redux/tbn';

interface CallLinkProps { phoneNumber: string, className?: string, stopPropagation?: boolean }

export const CallLink: FC<CallLinkProps> = ({
  phoneNumber,
  className,
  stopPropagation = false,
}) => {
  const [makeCall] = useMakeCall();

  const makePhoneCall = (evt: SyntheticEvent): void => {
    makeCall(phoneNumber);
    evt.preventDefault();
  };

  return (
    <a
      className={className}
      onClickCapture={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        makePhoneCall(e);
      }}
      href={`tel:${phoneNumber}`}
    >
      {phoneNumber}
    </a>
  );
};
