import { MachineColumn } from '../redux/leftFilterState';

export const translateErrorCode = (endPoint: string, errorText: string, errorCode = 500) => {
  if (errorText.includes('authentication')) {
    return 'Et autoriseringsproblem har oppstått';
  }
  if (errorCode === 403) {
    return 'En autoriseringsfeil har oppstått';
  }
  if (endPoint === 'moveTask') {
    return 'Kunne ikke flytte oppgave';
  }
  if (errorText === 'User not allowed to place task on worker') {
    return 'Du har ikke rettigheter til denne handlingen';
  }
  if (errorText === 'User cannot edit this task') {
    return 'Du har ikke rettigheter til denne handlingen';
  }
  if (errorText.includes('Substitute already exists at this time')) {
    return 'Det finnes allerede vikar i ønsket periode';
  }
  return 'En feil har oppstått';
};

export const translateColumnName = (c: MachineColumn) => {
  if (c === 'vehicleRegistrationPlateNumber') return 'Reg. Nr';
  if (c === 'internalNumber') return 'Internnummer';
  if (c === 'operatingTime') return 'Gangtid';
  if (c === 'mainCategoryName') return 'Kategori';
  if (c === 'subCategoryName') return 'U. Kategori';
  if (c === 'modelName') return 'Model';
  if (c === 'assignedDriver_fullName') return 'Sjåfør';
  if (c === 'gpsMake') return 'GPS';
  if (c === 'sorting') return 'Sortering';
  if (c === 'commentMP') return 'Kommentar MP';
  if (c === 'comment') return 'Kommentar';
  return c;
};
