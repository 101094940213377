export const calendarViews = ['maned', 'kvartal', 'ar'] as const;

export type CalendarView = typeof calendarViews[number];

export const view: {
  month: CalendarView,
  quarter: CalendarView,
  year: CalendarView,
} = {
  month: 'maned',
  quarter: 'kvartal',
  year: 'ar',
};
