/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SelectItem } from '../../shared/types/util/selectItem';

const stateName = 'viewSetting';

interface InitialState {
  showWeekend: boolean;
  showOrdersWithActiveProjectFilter: boolean;
  showOnlyAssignments: boolean;
  projectFilter: SelectItem[];
}

const initialState: InitialState = {
  showWeekend: false,
  showOrdersWithActiveProjectFilter: false,
  showOnlyAssignments: false,
  projectFilter: [],
};

const viewSettingSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setWeekend(state, action: PayloadAction<boolean>) {
      state.showWeekend = action.payload;
    },
    setShowOrdersWithActiveProjectFilter(state, action: PayloadAction<boolean>) {
      state.showOrdersWithActiveProjectFilter = action.payload;
    },
    setOnlyAssignment(state, action: PayloadAction<boolean>) {
      state.showOnlyAssignments = action.payload;
    },
    setProjectFilter(state, action: PayloadAction<SelectItem[]>) {
      state.projectFilter = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setWeekend,
  setShowOrdersWithActiveProjectFilter,
  setOnlyAssignment,
  setProjectFilter,
  reset,
} = viewSettingSlice.actions;

export default persistReducer({ key: stateName, storage }, viewSettingSlice.reducer);
