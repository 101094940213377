const stableArray: any[] = [];

type WithRequiredProperty<T, K extends keyof T> = T & {
  [P in K]-?: T[P];
};

/**
 * Returns the value or a stable array. Named for ease of use.
 *
 * Has some weird interaction with booleans sometimes where the type becomes
 * false rather than boolean.
 */
export const selectFromResult = <
  T extends {data?: Object[]|undefined}
>(e: T) => {
  const newVal = e.data === undefined ? { ...e, data: stableArray } : e;
  return newVal as WithRequiredProperty<T, 'data'>;
};
