import { Draggable } from '@fullcalendar/interaction';
import { useEffect, useState } from 'react';
import { getAttributes } from '../../../core/helpers/functions';

export const useDraggable = () => {
  const [draggable, setDraggable] = useState<Draggable|null>(null);
  const attachDraggable = () => {
    if (draggable) {
      return; // Draggable is already connected
    }
    const draggableEl = document.getElementById('external-events');
    if (draggableEl === null) {
      setTimeout(attachDraggable, 500);
      return;
    }
    // eslint-disable-next-line no-new
    const drag = new Draggable(draggableEl, {
      itemSelector: '.task-item',
      eventData(eventEl: { getAttribute: (arg0: string) => any }) {
        const title = eventEl.getAttribute('data-title');
        const id = eventEl.getAttribute('data-id');
        const duration = eventEl.getAttribute('data-duration');
        const extprops = getAttributes(eventEl, ['title', 'machinetype']);

        return {
          title,
          id,
          editable: true,
          duration,
          color: 'green',
          extendedProps: extprops,
        };
      },
    });
    setDraggable(drag);
  };

  useEffect(() => {
    attachDraggable();
  }, []);
  return draggable;
};
