import { CardActionArea } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import Card from '@mui/material/Card';
import { DateTime } from '../../../shared/types/util/dateTime';
import { autoFormat } from '../../../shared/logic/dates';

export const ListItem = ({
  // eslint-disable-next-line object-curly-newline
  data,
  onClick = () => null,
}: {
  data: {
    id: string,
    comment: string,
    from: DateTime,
    to: DateTime,
  },
  onClick?: (value: string) => void;
}) => {
  const {
    id,
    comment,
    from,
    to,
  } = data;
  return (
    <Card
      sx={{ mt: 2, p: 1 }}
      className="unavailability-view-list-item-partial"
    >
      <CardActionArea onClick={() => onClick(id)}>
        <div className="p8">
          <div className="line-wrapper">
            <div className="left">
              <BlockIcon className="transform-icon" fontSize="medium" /><span className="sub-name">{autoFormat(from, to)}</span>
            </div>
          </div>
          <div className="sub-period">
            {comment}
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
};
