import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { MultiSearchSelect } from '../../../../shared/components/multiSearchSelect';
import { setProjectFilter } from '../../../../core/redux/viewSetting';
import { useDispatch, useSelector } from '../../../../core/hooks/redux';
import { RootState } from '../../../../core/redux';
import { useGetAllProjectsQuery } from '../../../../shared/redux/machine';
import { useOrganizations } from '../../../../core/redux/leftFilterState';

export const ProjectFilter = () => {
  const orgs = useOrganizations();
  const { data: projects } = useGetAllProjectsQuery({ organizations: orgs });
  const projectFilter = useSelector((state: RootState) => state.viewSetting.projectFilter);
  const dispatch = useDispatch();

  const filteredProjects = useMemo(() => projects?.map((p) => ({ id: p.id, label: `${p.id} - ${p.projectName}` })) || [], [projects]);

  useEffect(() => {
    dispatch(setProjectFilter(projectFilter.filter((p) => filteredProjects.some((f) => f.id === p.id))));
  }, [orgs]);

  return (
    <Box width="15%" paddingRight={3}>
      <MultiSearchSelect
        label="Prosjekt"
        size="small"
        value={projectFilter}
        onChange={(p) => dispatch(setProjectFilter(p))}
      >
        {filteredProjects}
      </MultiSearchSelect>
    </Box>
  );
};
